import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Store } from "@softline/core";
import { Router } from "@angular/router";
import {
    BackNavigationService,
    BlockingLoadingSpinnerComponent,
    handleRequestErrors, PageHeaderComponent,
    SOFTLINE_FEATURE_TITLE, Step,
    StepHeaderComponent,
    TitleStore
} from "@softline/application";
import { MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule } from "@softline/ui-core";
import { SOFTLINE_FEATURE_LAGERKORREKTUR } from "../../lagerkorrektur.shared";
import { LagerkorrekturStore } from "../../store";
import { Lagersymbol, LagersymbolStore, SOFTLINE_FEATURE_LAGERSYMBOL } from "@softapps/lager/core";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'soft-lagersymbol-auswahl',
  standalone: true,
    imports: [CommonModule, UiCoreModule, StepHeaderComponent, BlockingLoadingSpinnerComponent, PageHeaderComponent],
  templateUrl: './lagersymbol-auswahl.page.html',
  styleUrls: ['./lagersymbol-auswahl.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LagersymbolAuswahlPage implements OnInit {

  readonly steps: Step[] = [
    {
      header: '#LAGERKORREKTUR.PAGES.LAGERPLATZ_SCANNEN.TITLE',
      subHeader: '#LAGERKORREKTUR.PAGES.LAGERPLATZ_SCANNEN.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR.PAGES.ARTIKEL_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR.PAGES.ARTIKEL_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR.PAGES.MENGEN_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR.PAGES.MENGEN_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR.PAGES.LAGERSYMBOL_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR.PAGES.LAGERSYMBOL_AUSWAHL.DESCRIPTION'
    },
  ];

  loading$ = new BehaviorSubject<boolean>(false);
  saving$ = new BehaviorSubject<boolean>(false);
  lagerplatz$ = this.store.observe(SOFTLINE_FEATURE_LAGERKORREKTUR, LagerkorrekturStore.getters.lagerplatz);
  artikel$ = this.store.observe(SOFTLINE_FEATURE_LAGERKORREKTUR, LagerkorrekturStore.getters.artikel);
  lagersymbole$ = this.store.observe(SOFTLINE_FEATURE_LAGERSYMBOL, LagersymbolStore.getters.all);

  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService) {
  }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#LAGERKORREKTUR.TITLE');
    this.loading$.next(true);
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_LAGERSYMBOL, LagersymbolStore.actions.loadManyOnce)
      this.loading$.next(false);
    }
    catch (e) {
      this.loading$.next(false);
      handleRequestErrors(this.store, e)
    }
  }

  ngOnDestroy() {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '')
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/lagerkorrektur/mengen-auswahl'])
  }

  async onSelect(lagersymbol: Lagersymbol): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_LAGERKORREKTUR, LagerkorrekturStore.mutations.setLagersymbol, lagersymbol);
    const lagerplatz = this.store.get(SOFTLINE_FEATURE_LAGERKORREKTUR, LagerkorrekturStore.getters.lagerplatz);
    const inhalt = this.store.get(SOFTLINE_FEATURE_LAGERKORREKTUR, LagerkorrekturStore.getters.artikel);
    this.saving$.next(true);
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_LAGERKORREKTUR, LagerkorrekturStore.actions.lagerkorrektur, {
        lagerplatzInhalt: {
          lagerplatz: lagerplatz.lagerplatz,
          inhalt: [inhalt],
        },
        lagersymbol
      })
      this.saving$.next(false);
      await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#LAGERKORREKTUR.MESSAGES.SUCCESS.KORREKTUR'
      )
      await this.router.navigate(['/lagerkorrektur'])
    }
    catch (e) {
      this.saving$.next(false);
      handleRequestErrors(this.store, e);
    }
  }
}
