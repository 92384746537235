import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationExtras, Router, RouterModule } from '@angular/router';
import { Command } from '../../../data/command';
import { asapScheduler, Observable, observeOn } from 'rxjs';
import { SOFTLINE_FEATURE_COMMANDS } from '../../../application.shared';
import * as CommandStore from '../../../program/command.store';
import { Store } from '@softline/core';
import { CommonModule } from '@angular/common';
import {IconComponent, UiCoreModule} from '@softline/ui-core';

@Component({
  standalone: true,
  selector: 'soft-side-actions',
  templateUrl: './side-actions.component.html',
  styleUrls: ['./side-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule, RouterModule],
})
export class SideActionsComponent implements OnInit {
  topCommands$: Observable<readonly Command[]> = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'action-menu-top'
    )
    .pipe(observeOn(asapScheduler));
  bottomCommands$: Observable<readonly Command[]> = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'action-menu-bottom'
    )
    .pipe(observeOn(asapScheduler));

  @Input() isMinified = false;

  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() execute = new EventEmitter();

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {}

  onClick(
    execute: (() => void) | undefined,
    routerLink: any[] | string | undefined,
    canExecute: boolean,
    routerLinkParams: NavigationExtras | undefined
  ): void {
    this.execute.emit();
    if (!canExecute) return;
    if (execute) execute();
    else if (routerLink)
      this.router.navigate(
        typeof routerLink === 'string' ? [routerLink] : routerLink,
        routerLinkParams
      );
  }

  filterCommand(command: Command, classname?: string): boolean {
    const exp = new RegExp(`\\b${classname}\\b`);
    return !!command.class && exp.test(command.class);
  }
}
