@if (strategy.items$ | async; as items) {
  <div class="flex flex-col sm:flex-col-reverse">
    <div class="flex flex-col max-h-[70vh] mt-2 overflow-y-auto">
      @if (strategy.loading()) {
        <div *softRepeat="20" class="w-full m-2 px-2 h-4 skeleton block"></div>
      }
      @else {
        @for (item of items; track item['id']) {
          <div class="flex flex-row hover:bg-hover cursor-pointer border-b border-b-solid p-2 items-center justify-center"
               (click)="strategy.resolve(item)">
            <div class="w-full">
              <ng-container *ngComponentOutlet="config.component; inputs: {item: item}"></ng-container>
            </div>
          </div>
        } @empty {
          <div class="flex flex-col text-light text-lg items-center content-center my-4 mt-2">
            <span>Es wurden keine Daten gefunden</span>
          </div>
        }
      }
    </div>
    <soft-filter-and-sort class="block mx-2 mt-2 mb-2 sm:mb-0"
                          [filter]="(filter$ | async) ?? null"
                          [filterConfig]="config.filter"
                          [sort]="(sort$ | async) ?? null"
                          [sortConfig]="config.sort"
                          [values]="items"
                          (filterChange)="onFilterChange($event)"
                          (sortChange)="onSortChange($event)">
    </soft-filter-and-sort>
  </div>
}
@else {
  <div class="flex flex-col text-light text-lg items-center content-center my-4 mt-2">
    <span>Es wurden noch keine Abfrage ausgeführt</span>
  </div>
}

