<div
  *ngIf="form && definition"
  class="soft-container soft-container-column"
  [formGroup]="form"
  [ngSwitch]="definition.control"
>
  <label class="soft-label soft-label-top">{{ definition.title }}</label>
  <soft-toggle-switch
    *ngSwitchCase="'switch'"
    [formControlName]="definition.name"
    [readonly]="definition.readonly | booleanRule: form.value"
    softFieldValidation
  >
    <span class="soft-label soft-label-right"> {{ definition.label }}</span>
  </soft-toggle-switch>
  <soft-checkbox
    *ngSwitchDefault
    [formControlName]="definition.name"
    [readonly]="definition.readonly | booleanRule: form.value"
    softFieldValidation
  >
    <span class="soft-label soft-label-right"> {{ definition.label }}</span>
  </soft-checkbox>
</div>
