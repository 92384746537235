import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Store } from "@softline/core";
import { Router } from "@angular/router";
import {
    BackNavigationService,
    BlockingLoadingSpinnerComponent,
    handleRequestErrors, PageHeaderComponent,
    SOFTLINE_FEATURE_TITLE, Step,
    StepHeaderComponent,
    TitleStore
} from "@softline/application";
import { MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule } from "@softline/ui-core";
import {
  SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL,
  SOFTLINE_FEATURE_UMBUCHUNGSARTIKEL
} from "../../lagerkorrektur-artikel.shared";
import { LagerkorrekturArtikelStore, UmbuchungsartikelStore } from "../../store";
import { Artikel } from "@softapps/lager/core";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'soft-umbuchungsartikel-auswahl',
  standalone: true,
    imports: [CommonModule, UiCoreModule, StepHeaderComponent, BlockingLoadingSpinnerComponent, PageHeaderComponent],
  templateUrl: './umbuchungsartikel-auswahl.page.html',
  styleUrls: ['./umbuchungsartikel-auswahl.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UmbuchungsartikelAuswahlPage implements OnInit {

  loading$ = new BehaviorSubject<boolean>(false);
  saving$ = new BehaviorSubject<boolean>(false);
  lagerplatz$ = this.store.observe(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.getters.lagerplatz);
  artikel$ = this.store.observe(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.getters.artikel);

  umbuchungsartikel$ = this.store.observe(SOFTLINE_FEATURE_UMBUCHUNGSARTIKEL, UmbuchungsartikelStore.getters.all);

  readonly steps: Step[] = [
    {
      header: '#LAGERKORREKTUR_ARTIKEL.PAGES.LAGERPLATZ_SCANNEN.TITLE',
      subHeader: '#LAGERKORREKTUR_ARTIKEL.PAGES.LAGERPLATZ_SCANNEN.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR_ARTIKEL.PAGES.ARTIKEL_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR_ARTIKEL.PAGES.ARTIKEL_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR_ARTIKEL.PAGES.MENGEN_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR_ARTIKEL.PAGES.MENGEN_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR_ARTIKEL.PAGES.UMBUCHUNGSARTIKEL_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR_ARTIKEL.PAGES.UMBUCHUNGSARTIKEL_AUSWAHL.DESCRIPTION'
    },
  ];
  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService) {
  }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#LAGERKORREKTUR_ARTIKEL.TITLE');
    this.loading$.next(true);
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_UMBUCHUNGSARTIKEL, UmbuchungsartikelStore.actions.loadManyOnce)
      this.loading$.next(false);
    }
    catch (e) {
      this.loading$.next(false);
      handleRequestErrors(this.store, e)
    }
  }

  ngOnDestroy() {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '')
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/lagerkorrektur-artikel/mengen-auswahl'])
  }

  async onSelect(umbuchungsartikel: Artikel): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.mutations.setUmbuchungsartikel, umbuchungsartikel);
    const lagerplatz = this.store.get(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.getters.lagerplatz);
    const inhalt = this.store.get(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.getters.artikel);
    this.saving$.next(true);
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.actions.lagerkorrektur, {
        lagerplatzInhalt: {
          lagerplatz: lagerplatz.lagerplatz,
          inhalt: [inhalt],
        },
        umbuchungsartikel
      })
      this.saving$.next(false);
      await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#LAGERKORREKTUR_ARTIKEL.MESSAGES.SUCCESS.KORREKTUR'
      )
      await this.router.navigate(['/lagerkorrektur-artikel'])
    }
    catch (e) {
      this.saving$.next(false);
      handleRequestErrors(this.store, e);
    }
  }
}
