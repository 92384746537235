<h4>{{ title }}</h4>

<soft-dynamic-form
  [definition]="$any(definition)"
  [value]="value"
  (submit)="close($event)"
  #form
></soft-dynamic-form>
<div class="c row end">
  <button
    class="soft-button primary mt-2"
    [disabled]="form.form.errors"
    (click)="form.formRef?.ngSubmit?.emit()"
  >
    Ändern
  </button>
</div>
