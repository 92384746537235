import { Component, Input, OnInit } from '@angular/core';
import { ListDefinition } from '../../../../data/definitions';

@Component({
  selector: 'soft-dynamic-list-value',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  @Input() values?: any[];
  @Input() definition?: ListDefinition;

  constructor() {}

  ngOnInit(): void {}
}
