import { InjectionToken } from '@angular/core';
import { Appearance } from './data/appearance';
import { Theme } from './data/theme';

export const SOFTLINE_SETTINGS_APPEARANCE = 'appearance';

export const SOFTLINE_CONFIG_FONT_SIZES = new InjectionToken<Appearance>(
  'SOFTLINE_CONFIG_FONT_SIZES'
);
export const SOFTLINE_CONFIG_THEMES = new InjectionToken<Theme>(
  'SOFTLINE_CONFIG_THEMES'
);
