import { Component, Input, OnInit, Type } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FieldOkItemComponent,
  FieldOkViewComponent,
} from "@softline/dynamic";
import { UiCoreModule } from "@softline/ui-core";
import { FieldOkItemOutletDirective } from "../../../../directives/field-ok-item-outlet.directive";
import { FilterAndSortComponent, FilterConfig, SortConfig } from "@softline/application";
import { FieldOkStrategy } from "../../strategies/field-ok.strategy";
import { select } from "@softline/core";
import { WithFilterAndSort } from "../field-ok-view-filter-and-sort.mixin";

export interface FieldOkMasterDetailViewConfig {
  masterComponent: Type<FieldOkItemComponent<any>>;
  detailComponent: Type<FieldOkItemComponent<any>>;
  filter?: FilterConfig;
  sort?: SortConfig;
}

@Component({
  selector: 'soft-field-ok-master-deftail-view',
  standalone: true,
  imports: [CommonModule, UiCoreModule, FieldOkItemOutletDirective, FilterAndSortComponent],
  templateUrl: './field-ok-master-detail-view.component.html',
  styleUrls: ['./field-ok-master-detail-view.component.scss'],
})
export class FieldOkMasterDetailViewComponent extends WithFilterAndSort() implements OnInit, FieldOkViewComponent<FieldOkMasterDetailViewConfig>{

  @Input() config!: FieldOkMasterDetailViewConfig;

  constructor() {
    super()
  }

  async ngOnInit(): Promise<void> { }
}
