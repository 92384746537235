<div
  *ngIf="isSideMenuOpen$ | async"
  class="sidebar left extended"
  [class.z-502]="(isSideMenuMinified$ | async) === false"
  [class.shadow-left]="(isSideMenuMinified$ | async) === false"
  @slideInOut_Main
>
  <soft-side-menu
    (close)="
      isTabletScreen || isDesktopScreen ? minimizeSideMenu() : closeSideMenu()
    "
    (execute)="
      isTabletScreen
        ? minimizeSideMenu()
        : !isDesktopScreen
        ? closeSideMenu()
        : undefined
    "
    (open)="maximizeSideMenu()"
    [isMinified]="
      (isSideMenuMinified$ | async) === true ||
      ((isSideMenuMinified$ | async) === null && isDesktopScreen === false)
    "
  >
  </soft-side-menu>
</div>
<div class="main flex flex-col w-full" [class.actions]="hasSideActions$ | async">
  <soft-title-bar class="soft-title-bar"></soft-title-bar>
  <soft-pull-to-refresh-container
    class="content shell-content flex flex-grow flex-col"
    [class.content-tablet]="isTabletScreen"
    [canRefresh]="(refreshService.canRefresh$ | async) ?? false"
    (refresh)="refreshService.refresh()"
  >
    <router-outlet></router-outlet>
  </soft-pull-to-refresh-container>
  <soft-bottom-bar></soft-bottom-bar>
</div>
<div
  class="shell-message-bar-wrapper"
  *ngIf="(messages$ | async)?.length"
  [class.side-menu-opened]="
    (isTabletScreen || isDesktopScreen) &&
    (isSideMenuOpen$ | async) &&
    !(isSideMenuMinified$ | async) &&
    !((isActionMenuOpen$ | async) && (isActionMenuMinified$ | async) === false)
  "
  [class.action-menu-opened]="
    (isTabletScreen || isDesktopScreen) &&
    !((isSideMenuOpen$ | async) && !(isSideMenuMinified$ | async)) &&
    (isActionMenuOpen$ | async) &&
    (isActionMenuMinified$ | async) === false
  "
  [class.both-menues-opened]="
    (isTabletScreen || isDesktopScreen) &&
    (isSideMenuOpen$ | async) &&
    !(isSideMenuMinified$ | async) &&
    (isActionMenuOpen$ | async) &&
    (isActionMenuMinified$ | async) === false
  "
>
  <soft-message-bar-container
    class="shell-message-bar-container"
  ></soft-message-bar-container>
</div>

<soft-modal-container></soft-modal-container>

<div
  *ngIf="(isActionMenuOpen$ | async) && (hasSideActions$ | async)"
  class="sidebar right extended-actions"
  [class.z-502]="(isActionMenuMinified$ | async) === false"
  [class.shadow-right]="(isActionMenuMinified$ | async) === false"
  @slideInOut_Actions
>
  <soft-side-actions
    (close)="
      isTabletScreen || isDesktopScreen
        ? minimizeActionMenu()
        : closeActionMenu()
    "
    (execute)="
      isTabletScreen
        ? minimizeActionMenu()
        : !isDesktopScreen
        ? closeActionMenu()
        : undefined
    "
    (open)="maximizeActionMenu()"
    [isMinified]="
      (isActionMenuMinified$ | async) === true ||
      (isActionMenuMinified$ | async) === null
    "
    [class.sidebar-minified]="
      (isActionMenuMinified$ | async) === true ||
      (isActionMenuMinified$ | async) === null
    "
  >
  </soft-side-actions>
</div>

<div
  *ngIf="(isSideMenuOpen$ | async) && (isSideMenuMinified$ | async) === false"
  (click)="isTabletScreen ? minimizeSideMenu() : closeSideMenu()"
  class="overlay"
></div>
<div
  *ngIf="
    (isActionMenuOpen$ | async) && (isActionMenuMinified$ | async) === false
  "
  (click)="isTabletScreen ? minimizeActionMenu() : closeActionMenu()"
  class="overlay"
></div>
