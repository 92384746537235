<div class="flex flex-col-reverse full-width bg-surface lg:bg-transparent -mt-2">
  <soft-step-header [steps]="steps" [currentIndex]="1" #stepper></soft-step-header>
  <soft-page-header [overline]="stepper.stepText"
                    [title]="stepper.current?.header | translate"
                    [subtitle]="stepper.current?.subHeader | translate"></soft-page-header>
</div>

<ng-container *ngIf="lagerstandArtikel$ | async as lagerstandArtikel">
  <ng-container *ngIf="lagerstandArtikel.length > 0">
    <div *ngIf="lagerstandArtikel[0].artikel?.artikel as artikel"
         class="flex flex-col border border-gray-400 rounded p-3 mt-3">
      <span class="text-xs color-light">{{artikel.nummer}}</span>
      <span class="font-bold">{{artikel.artbez1}}</span>
      <span class="">{{artikel.artbez2}}</span>
      <span class="">{{artikel.artbez3}}</span>
      <span class="">{{artikel.artbez4}}</span>
      <span class="">{{artikel.artbez5}}</span>
    </div>
  </ng-container>


  <table class="soft-table soft-table-striped mt-4">
    <thead>
      <tr class="!bg-primary text-primary-contrast">
        <th class="!bg-primary">Lager</th>
        <th class="!bg-primary !text-right">Menge</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let current of lagerstandArtikel">
      <td>{{current.lagerplatz.lagerplatz}}</td>
      <td>
        <div class="flex flex-col items-end justify-center">
          <span>{{current.artikel.menge}} {{current.artikel.einheit.arteh}}</span>
          <span *ngIf="current.artikel.einheit.id !== current.artikel.einheit_lg.id">
            {{current.artikel.menge_lg}} {{current.artikel.einheit_lg.arteh}}
          </span>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

</ng-container>
<soft-blocking-loading-spinner *ngIf="loading$ | async" class="top-0 right-0"></soft-blocking-loading-spinner>
