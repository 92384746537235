import { inject, Injectable, Injector } from "@angular/core";
import { FormGroup, UntypedFormControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { ControlDefinition, Definition, isControlDefinition, ObjectDefinition } from "@softline/dynamic";
import { DateParser, Validators } from "@softline/ui-core";
import { RuleHelper } from "../utilities/rule.helper";
import { AffectedHelper } from "../utilities/affected.helper";

@Injectable()
export class DynamicFormCreator {

  constructor(private dateParser: DateParser<any>, private injector: Injector) {}

  createForm(definition: ObjectDefinition): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({}, { updateOn: 'blur' });
    const stack: Definition[] = [...definition.definitions];
    const groups: { formGroup: UntypedFormGroup; endOn: Definition }[] = [];
    while (stack.length > 0) {
      const current = stack.pop();

      if (!current) continue;

      if (groups.length > 0 && groups[groups.length - 1]?.endOn === current)
        groups.pop();

      const currentFormGroup =
        groups.length > 0 ? groups[groups.length - 1]?.formGroup : formGroup;

      if (current.type === 'object') {
        const newFormGroup = new UntypedFormGroup({}, { updateOn: 'blur' });
        currentFormGroup.addControl(current.name, newFormGroup);
        groups.push({
          formGroup: newFormGroup,
          endOn: stack[stack.length - 1],
        });
        stack.push(...current.definitions);
      } else if (isControlDefinition(current) && currentFormGroup)
        currentFormGroup.addControl(current.name, this.createControl(current));
      else if (current.type === 'group') stack.push(...current.definitions);
    }

    return formGroup;
  }

  createControl(definition: ControlDefinition): UntypedFormControl {
    let updateOn: 'blur' | 'change' | 'submit' = 'blur';
    let defaultValue: unknown = definition.default ?? null;
    switch (definition.type) {
      case 'select':
        updateOn = 'change';
        break;
      case 'boolean':
        updateOn = 'change';
        defaultValue = defaultValue ?? false;
        break;
      case 'date':
        updateOn = 'change';
        if(typeof defaultValue === 'string' && defaultValue !== "")
          defaultValue = this.dateParser.parse(defaultValue)
        break;
      case 'string':
        updateOn = 'blur';
        break;
    }
    const validators: ValidatorFn[] = [];
    if (definition.required) validators.push(Validators.required());
    if (definition.validations)
      validators.push(RuleHelper.getValidator(definition.validations, this.injector));

    const control = new UntypedFormControl(defaultValue, {
      updateOn,
      validators,
    });

    if (definition.affects)
      control.valueChanges.subscribe(o => {
        const parent = control.parent
        if(!(parent instanceof FormGroup))
          return;
        const patch = AffectedHelper.getAffectedPatch(definition.affects, {...parent.value, [definition.name]: o}, this.injector);
        if(parent instanceof FormGroup)
          parent.patchValue(patch, {emitEvent: false});
      })
    return control;
  }
}
