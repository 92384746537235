import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";
import {
  AddOnHost, AddOnService,
  BackNavigable,
  BackNavigationService,
  BlockingLoadingSpinnerComponent,
  handleRequestErrors,
  MengenEingabeComponent, Scan,
  ScannerModule,
  ScannerStore,
  SOFTLINE_FEATURE_SCANNER,
  SOFTLINE_FEATURE_TITLE,
  StepHeaderComponent,
  TitleStore
} from "@softline/application";
import {
  asapScheduler,
  BehaviorSubject,
  combineLatestWith,
  firstValueFrom,
  map,
  Observable,
  observeOn,
  skip,
  Subscription,
  switchMap
} from "rxjs";
import { Store } from "@softline/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SOFTLINE_FEATURE_KOMMISSIONIERUNG } from "@softapps/lager/kommissionierung";
import { KommissionierungStore } from "../../store";
import { KommissionsauftragBewe, KommissionsauftragKopf } from "../../types/kommissionsauftrag";
import {
  Lagerplatz,
  LagerplatzInhalt, LagerplatzSucheComponent,
  LagerplatzSucheStore,
  SOFTLINE_FEATURE_LAGERPLATZ_SUCHE
} from "@softapps/lager/core";
import { KommmissionierungStrategy } from "../../strategies/kommmissionierung.strategy";
import { kommissionierungStrategyFactory } from "../../strategies/kommissionierung.strategy-factory";

@Component({
  selector: 'soft-artikel-menge',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ScannerModule, StepHeaderComponent, MengenEingabeComponent, BlockingLoadingSpinnerComponent],
  templateUrl: './artikel-menge.page.html',
  styleUrls: ['./artikel-menge.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArtikelMengePage implements OnInit, OnDestroy, BackNavigable, AddOnHost {

  menge: number = 0;

  private auftragSubscription?: Subscription;
  private beweSubscription?: Subscription;
  private scanSubscription?: Subscription;
  strategy$ = new BehaviorSubject<KommmissionierungStrategy | undefined>(undefined)
  loading$ = new BehaviorSubject<boolean>(false);
  disabled$ = new BehaviorSubject<boolean>(false);

  auftrag$: Observable<KommissionsauftragKopf> = this.route.paramMap.pipe(
    map(o => +(o.get('idkopfkomm') ?? 0)),
    switchMap(o => this.store.observe(SOFTLINE_FEATURE_KOMMISSIONIERUNG, KommissionierungStore.getters.entity, o)),
    observeOn(asapScheduler)
  )

  bewe$: Observable<KommissionsauftragBewe | undefined> = this.route.paramMap.pipe(
    map(o => +(o.get('idbewekomm') ?? 0)),
    combineLatestWith(this.auftrag$),
    map(([idbewekomm, auftrag]) => auftrag?.bewegungen?.find(o => o.id === idbewekomm)),
    observeOn(asapScheduler)
  )

  lagerplatz$: Observable<LagerplatzInhalt> =  this.route.paramMap
    .pipe(
      map(o => ({idkopfkomm: +(o.get('idkopfkomm') ?? 0), idbewekomm: +(o.get('idbewekomm') ?? 0)})),
      switchMap(o => this.store.observe(SOFTLINE_FEATURE_KOMMISSIONIERUNG, KommissionierungStore.getters.lagerplatzvorschlag, o))
    );
  menge$: Observable<number> = this.route.paramMap
    .pipe(
      map(o => ({idkopfkomm: +(o.get('idkopfkomm') ?? 0), idbewekomm: +(o.get('idbewekomm') ?? 0)})),
      switchMap(o => this.store.observe(SOFTLINE_FEATURE_KOMMISSIONIERUNG, KommissionierungStore.getters.menge, o))
    );
  maxMenge$ = this.strategy$
    .pipe(
      combineLatestWith(this.lagerplatz$, this.bewe$),
      map(([strategy, lagerplatz, bewe]) => {
        if(!strategy)
          return 0;
        return strategy.getMaxMenge(lagerplatz, bewe?.artikelMengeOffen);
      })
    )

  constructor(private store: Store,
              private router: Router,
              private route: ActivatedRoute,
              private backNavigationService: BackNavigationService,
              private cdRef: ChangeDetectorRef,
              private addOnService: AddOnService,
              public vcRef: ViewContainerRef) {
  }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    await this.addOnService.attachTo(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#KOMMISSIONIERUNG.TITLE');
    this.auftragSubscription = this.auftrag$.subscribe(o => {
      if(o)
        this.strategy$.next(kommissionierungStrategyFactory(o));
      this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setSubTitle, {
        subTitle: '#KOMMISSIONIERUNG.SUBTITLE',
        params: o
      });
      this.cdRef.detectChanges();
    })
    this.beweSubscription = this.bewe$
      .pipe(combineLatestWith(this.strategy$))
      .subscribe(
      async ([bewe, strategy]) => {
        if(!bewe || !strategy?.loadLagerplatzVorschlag)
          return;
        await this.loadLagerplatzVorschlag(bewe);
      }
    )
    this.scanSubscription = this.store.observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
      .pipe(skip(1))
      .subscribe(
      async scan => {
        if(!scan)
          return;
        await this.loadLagerplatz(scan);
      }
    )
  }

  async ngOnDestroy(): Promise<void> {
    if (this.auftragSubscription && !this.auftragSubscription.closed)
      this.auftragSubscription.unsubscribe();
    this.auftragSubscription = undefined;
    if (this.scanSubscription && !this.scanSubscription.closed)
      this.scanSubscription.unsubscribe();
    this.scanSubscription = undefined;
    if (this.beweSubscription && !this.beweSubscription.closed)
      this.beweSubscription.unsubscribe();
    this.beweSubscription = undefined;

    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setSubTitle, {
      subTitle: '',
      params: undefined
    })
    this.backNavigationService.set(undefined);
    await this.addOnService.detachFrom(this);
  }

  async navigateBack(): Promise<void> {
    const auftrag = await firstValueFrom(this.auftrag$);
    const bewes = auftrag?.bewegungen?.filter(o => o.artikelMengeOffen.menge > 0) ?? []
    if(bewes.length >= 1)
      await this.router.navigate(['/kommissionierung', auftrag.id]);
    else
      await this.router.navigate(['/kommissionierung']);
  }

  @HostListener('window:keydown.arrowright', ['$event'])
  async next(): Promise<void> {
    if(this.disabled$.value)
      return;

    const bewe = await firstValueFrom(this.bewe$);
    const auftrag = await firstValueFrom(this.auftrag$);
    if (!bewe || !auftrag)
      return;
    let index = auftrag?.bewegungen?.indexOf(bewe) ?? 0;
    if(index >= ((auftrag?.bewegungen?.length ?? 0) - 1))
      index = 0;
    else
      index++;
    const idbewekomm = (auftrag?.bewegungen ?? [])[index]?.id;
    await this.router.navigate(['/kommissionierung', auftrag.id, idbewekomm])
  }

  @HostListener('window:keydown.arrowleft', ['$event'])
  async previous(): Promise<void> {
    if(this.disabled$.value)
      return;

    const bewe = await firstValueFrom(this.bewe$);
    const auftrag = await firstValueFrom(this.auftrag$);
    if (!bewe ||!auftrag)
      return;
    let index = auftrag?.bewegungen?.indexOf(bewe) ?? 0;
    if(index === 0)
      index = (auftrag?.bewegungen?.length ?? 0) -1;
    else
      index--;
    const idbewekomm = (auftrag?.bewegungen ?? [])[index]?.id;
    await this.router.navigate(['/kommissionierung', auftrag.id, idbewekomm])
  }

  @HostListener('window:keydown.enter', ['$event'])
  async onSubmit(): Promise<void> {
    if(this.disabled$.value)
      return;

    const idkopfkomm = +(this.route.snapshot.paramMap.get('idkopfkomm') ?? 0)
    const idbewekomm = +(this.route.snapshot.paramMap.get('idbewekomm') ?? 0)

    if (this.loading$.getValue())
      return;

    this.loading$.next(true);
    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_KOMMISSIONIERUNG,
        KommissionierungStore.actions.artikelKommissionierung,
        {idkopfkomm, idbewekomm}
      );

      const bewe = result?.bewegungen?.find(o => o.id === idbewekomm);
      if(!bewe)
        throw new Error('[KommissionierungStoreExtension]: No Bewe found');

      const strategy = this.strategy$.value;
      if(!strategy)
        return;

      this.store.commit(SOFTLINE_FEATURE_KOMMISSIONIERUNG, KommissionierungStore.mutations.removeLagerplatzvorschlag,
        {idkopfkomm, idbewekomm});

      let lagerplatz: LagerplatzInhalt | undefined;
      if(strategy?.loadLagerplatzVorschlag && bewe.artikelMengeOffen.menge > 0) {
        lagerplatz = await this.store.dispatch(SOFTLINE_FEATURE_KOMMISSIONIERUNG, KommissionierungStore.actions.loadLagerplatzVorschlag,
          {idkopfkomm, idbewekomm, artikelMenge: bewe.artikelMengeOffen})
      }

      const menge = strategy.getMaxMenge(lagerplatz, bewe.artikelMengeOffen);
      this.store.commit(SOFTLINE_FEATURE_KOMMISSIONIERUNG, KommissionierungStore.mutations.setMenge,
        {idkopfkomm, idbewekomm, menge});

      this.loading$.next(false);

      const bewes = result?.bewegungen?.filter(o => o.artikelMengeOffen.menge > 0)
      if((bewes?.length ?? 0) >= 1)
        await this.router.navigate(['/kommissionierung', result.id]);
      else
        await this.router.navigate(['/kommissionierung', result.id, 'abschluss']);
    }
    catch (e) {
      this.loading$.next(false);
      handleRequestErrors(this.store, e)
    }
    finally {
      this.loading$.next(false);
    }
  }

  async onScan(): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_SCANNER, ScannerStore.actions.scan, {});
  }

  async loadLagerplatzVorschlag(bewe: KommissionsauftragBewe): Promise<void> {
    this.loading$.next(true);
    const idkopfkomm = +(this.route.snapshot.paramMap.get('idkopfkomm') ?? 0)
    try {
      const strategy = this.strategy$.value;
      if(!strategy)
        return;

      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_KOMMISSIONIERUNG,
        KommissionierungStore.actions.loadLagerplatzVorschlag,
        {idkopfkomm, idbewekomm: bewe.id, artikelMenge: bewe.artikelMengeOffen});

      const menge = strategy.getMaxMenge(result, bewe.artikelMengeOffen);
      this.store.commit(SOFTLINE_FEATURE_KOMMISSIONIERUNG, KommissionierungStore.mutations.setMenge,
        { idkopfkomm, idbewekomm: bewe.id, menge });
      this.loading$.next(false);
    }
    catch (e) {
      this.loading$.next(false);
      handleRequestErrors(this.store, e);
    }
    finally {
      this.loading$.next(false);
    }
  }

  async loadLagerplatz(scan: Scan): Promise<void> {
    const idkopfkomm = +(this.route.snapshot.paramMap.get('idkopfkomm') ?? 0)
    const idbewekomm = +(this.route.snapshot.paramMap.get('idbewekomm') ?? 0)
    this.loading$.next(true);
    try {
      const lagerplatz: Lagerplatz | undefined = await this.store.dispatch(
        SOFTLINE_FEATURE_LAGERPLATZ_SUCHE,
        LagerplatzSucheStore.actions.suche,
        scan.data
      );
      const strategy = this.strategy$.value;
      if(!lagerplatz || !strategy)
        return;
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_KOMMISSIONIERUNG,
        KommissionierungStore.actions.loadLagerplatz,
        {idkopfkomm, idbewekomm, code: lagerplatz.barcode})
      this.loading$.next(false);

      const bewe = await firstValueFrom(this.bewe$);
      const menge = strategy.getMaxMenge(result, bewe?.artikelMengeOffen);
      this.store.commit(SOFTLINE_FEATURE_KOMMISSIONIERUNG, KommissionierungStore.mutations.setMenge,
        { idkopfkomm, idbewekomm, menge });
    }
    catch (e) {
      this.loading$.next(false);
      handleRequestErrors(this.store, e);
    }
    finally {
      this.loading$.next(false);
    }
  }

  onMengeChange(idkopfkomm: number, idbewekomm: number, menge: number): void {
    this.store.commit(SOFTLINE_FEATURE_KOMMISSIONIERUNG, KommissionierungStore.mutations.setMenge,
      { idkopfkomm, idbewekomm, menge })
  }
}
