import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore, UiCoreModule } from '@softline/ui-core';
import { NavigationExtras } from '@angular/router';
import { interpolate, Store } from '@softline/core';

export interface Step {
  header: string;
  subHeader?: string;
  routerLink?: any[] | string;
  routerLinkParams?: NavigationExtras;
  execute?: () => void;
}

@Component({
  selector: 'soft-step-header',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss']
})
export class StepHeaderComponent {

  @Input() steps: Step[] = [];
  @Input() currentIndex: number = 0;
  @Input() showLabels: boolean = true;

  get current(): Step {
    return this.steps[this.currentIndex];
  }

  get stepText(): string {
    const translation = this.store.get(
      SOFTLINE_FEATURE_TRANSLATION,
      TranslationStore.getters.translation,
      '#APPLICATION.COMPONENTS.STEP_HEADER.STEP'
    );
    return interpolate(translation, {step: this.currentIndex + 1, total: this.steps?.length})
  }

  constructor(private store: Store) { }
}
