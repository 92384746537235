import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {RemoteConfigResolver} from '@softline/application';
import {InventurResolver} from './resolver/inventur.resolver';
import {SOFTLINE_PERMISSION_MDE_INVENTUR} from './inventur.shared';

export const lagerInventurRoutes: Routes = [
  {
    path: 'mde-inventur',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/inventur.component').then(
            (o) => o.InventurComponent
          ),
        children: [
          {
            path: 'eingabe-lager',
            loadComponent: () => import('./pages/lagereingabe/lagereingabe.component').then(
              o => o.LagereingabeComponent
            )
          },
          {
            path: 'eingabe-lagerplatz',
            loadComponent: () => import('./pages/lagerplatz-eingabe/lagerplatz-eingabe.component').then(
              o => o.LagerplatzEingabeComponent
            )
          },
          {
            path: 'eingabe-belegdatum',
            loadComponent: () => import('./pages/belegdatum-eingabe/belegdatum-eingabe.component').then(
              o => o.BelegdatumEingabeComponent
            )
          },
          {
            path: 'artikel-erfassen',
            loadComponent: () => import('./pages/artikel-erfassen/artikel-erfassen.component').then(
              c => c.ArtikelErfassenComponent
            ),
            loadChildren: () => [
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt1/schritt1.component').then(
                  c => c.Schritt1Component
                ),
                path: 'suche'
              },
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt2/schritt2.component').then(
                  c => c.Schritt2Component
                ),
                path: ':idartstamm'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/mde-inventur/artikel-erfassen/suche'
              }
            ]
          },
          {
            path: '',
            loadComponent: () => import('./pages/erfassung/erfassung.component').then(
              o => o.ErfassungComponent
            )
          },
          {
            path: '**',
            redirectTo: '/mde-inventur'
          },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_MDE_INVENTUR },
    resolve: {
      config: RemoteConfigResolver,
      inventur: InventurResolver
    },
    providers: [],
    runGuardsAndResolvers: 'always'
  },
];
