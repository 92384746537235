<ng-container *ngIf="definition$ | async as definition; else loading">
  <soft-dynamic-form
    [value]="value$ | async"
    [definition]="$any(definition)"
    (submit)="onSubmit($event ?? {})"
    #form
  ></soft-dynamic-form>
  <div class="c row end mt-4">
    <button
      class="soft-button primary"
      [disabled]="saving$ | async"
      (click)="onSubmit(form.form.value)"
    >
      <i *ngIf="saving$ | async" class="fa-regular fa-spinner fa-spin"></i>
      {{ '#UTILITIES.SETTINGS.USER_CONTACT.SUBMIT' | translate }}
    </button>
  </div>
</ng-container>
<ng-template #loading>
  <soft-loading-spinner *ngIf="loading$ | async"></soft-loading-spinner>
  <div class="c row center">
    <h5 *ngIf="!(loading$ | async)" class="font-semibold color light">
      {{ '#UTILITIES.SETTINGS.USER_CONTACT.NO_DEFINITION' | translate }}
    </h5>
  </div>
</ng-template>
