<ng-container *ngIf="definition as def" [ngSwitch]="def.control">
  <soft-tab-group
    *ngSwitchCase="'tab-group'"
    [ngClass]="def.class | classRule: form.value"
  >
    <ng-container *ngFor="let tab of def.definitions">
      <soft-tab
        *ngIf="tab.visible | booleanRule: form.value:true"
        [ngClass]="tab.class | classRule: form.value"
        [title]="tab.title"
      >
        <ng-container
          *ngTemplateOutlet="
            valueTemplate;
            context: { $implicit: $any(tab).definitions }
          "
        ></ng-container>
      </soft-tab>
    </ng-container>
  </soft-tab-group>
  <div *ngSwitchCase="'card'"
       class="w-full"
  >
    <div *ngIf="def.title" class="soft-overline">
      {{ def.title }}
    </div>
    <div class="soft-card w-full"
         [ngClass]="def.class | classRule: form.value">
      <ng-container
        *ngTemplateOutlet="
        valueTemplate;
        context: { $implicit: def.definitions }
      "
      ></ng-container>
    </div>
  </div>
  <section *ngSwitchCase="'section'"
    class="soft-section w-full"
  >
    <div *ngIf="def.title" class="soft-overline">{{ def.title }}</div>
    <div [ngClass]="def.class | classRule: form.value">
      <ng-container
        *ngTemplateOutlet="
        valueTemplate;
        context: { $implicit: def.definitions }
      "
      ></ng-container>
    </div>
  </section>
  <div *ngSwitchCase="'div'"
       class="w-full"
  >
    <div class="soft-overline" *ngIf="def.title">{{ def.title }}</div>
    <div [ngClass]="def.class | classRule: form.value">
      <ng-container
        *ngTemplateOutlet="
        valueTemplate;
        context: { $implicit: def.definitions }
      "
      ></ng-container>
    </div>
  </div>
</ng-container>

<ng-template let-children #valueTemplate>
  <ng-container *ngFor="let child of children">
    <div
      [ngClass]="child.class | classRule: form.value"
      class="soft-dynamic-form-field"
    >
      <ng-container
        *ngIf="child.visible | booleanRule: form.value:true"
        softDynamicFormField
        [definition]="child"
        [form]="form"
      ></ng-container>
    </div>
  </ng-container>
</ng-template>
