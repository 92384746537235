<div class="flex flex-row items-center justify-between w-full bg-secondary-300 border border-secondary-700 border-solid p-2">
  <div class="flex flex-row items-center gap-2 flex-wrap">
    <div class="bg-secondary-500 text-secondary-contrast text-sm rounded-full px-2 whitespace-nowrap cursor-pointer hover:underline"
         [ngClass]="{'italic !text-light soft-label required': !filter?.property}"
         (click)="onPropertyClick()">
      {{(filter?.property ? (filter?.property | filterPropertyName:config) : '#APPLICATION.FILTER_AND_SORT.FILTER.FIELD_PLACEHOLDER') | translate }}
    </div>
    <div *ngIf="filter?.property as property"
         class="bg-secondary-500 text-secondary-contrast text-sm rounded-full px-2 whitespace-nowrap cursor-pointer hover:underline"
         [ngClass]="{'italic !text-light soft-label required': !filter.operator}"
         (click)="onOperatorClick(property)">
      {{(filter.operator ? (filter.operator | filterOperator)?.title : '#APPLICATION.FILTER_AND_SORT.FILTER.OPERATOR_PLACEHOLDER') | translate}}
    </div>
    <ng-container *ngIf="filter.operator | filterOperator as operator">
      <div class="bg-secondary-500 text-secondary-contrast text-sm rounded-full px-2 whitespace-nowrap">
        <ng-container [softFilterValue]="filter"
                      [values]="values"
                      [config]="config"
                      (filterChange)="filterChange.emit($event)" ></ng-container>
      </div>
    </ng-container>
  </div>
  <div class="text-danger cursor-pointer"
       (click)="onDelete()">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-4 h-4">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </div>
</div>
