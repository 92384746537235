import { Inject, Injectable } from '@angular/core';
import { LocalStorageService, Store } from '@softline/core';
import { Appearance } from '../data/appearance';
import { lastValueFrom, Subscription } from 'rxjs';
import { SOFTLINE_FEATURE_SETTINGS } from '../../application.shared';
import * as SettingsStore from '../../settings/settings.store';
import { SOFTLINE_SETTINGS_APPEARANCE } from '../appearance.shared';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AppearanceService {
  private subscription: Subscription;

  constructor(
    private store: Store,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.subscription = this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<Appearance>(),
        SOFTLINE_SETTINGS_APPEARANCE
      )
      .subscribe((o) => {
        this.apply(o);
      });
  }

  apply(appearance: Appearance | undefined): void {
    const element = this.document.documentElement;
    if (appearance?.theme) element.setAttribute('theme', appearance?.theme);
    else element.removeAttribute('theme');

    if (appearance?.fontSize)
      element.setAttribute('style', `font-size:${appearance.fontSize}`);
    else element.removeAttribute('style');
  }
}
