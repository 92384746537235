import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@softline/core';
import * as TranslationStore from '../translation.store';
import { SOFTLINE_FEATURE_TRANSLATION } from '../i18n.shared';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform, OnDestroy {
  private subscription?: Subscription;

  constructor(
    private store: Store,
    private changeDetector: ChangeDetectorRef
  ) {}

  transform(
    value: string | undefined | null,
    module?: string,
    language?: string
  ): string | null | undefined {
    if (!value) return value;

    this.subscription = this.store
      .observe(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.getters.translation,
        value
      )
      .subscribe((val) => {
        this.subscription?.unsubscribe();
        this.changeDetector.markForCheck();
      });

    return this.store.get(
      SOFTLINE_FEATURE_TRANSLATION,
      TranslationStore.getters.translation,
      value
    );
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }
}
