import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import {
  SOFTLINE_FEATURE_MDE_INVENTUR,
  SOFTLINE_FEATURE_MDE_INVENTUR_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_INVENTUR_BEWE
} from '../inventur.shared';
import {InventurBeweStore} from '../store/inventur-bewe.store';
import {combineLatestWith, map, Subscription} from 'rxjs';
import {InventurStore} from '../store/inventur.store';
import {InventurArtikelDetailStore} from '../store/inventur-artikel-detail.store';
import {InventurKopf} from '../data/inventur-kopf';

@Component({
  selector: 'soft-mde-inventur',
  standalone: true,
  templateUrl: './inventur.component.html',
  styleUrls: ['./inventur.component.scss'],
  imports: [RouterModule],
})
export class InventurComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  readonly kopf$ = this.store.observe(
    SOFTLINE_FEATURE_MDE_INVENTUR,
    InventurStore.getters.kopf
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Inventur'
    );

    const data = this.activatedRoute.snapshot.data as { kopf?: InventurKopf | null };

    if (data?.kopf && Object.keys(data?.kopf).length > 0)
      await this.router.navigate(['/mde-inventur']);

    this.subscription = this.activatedRoute.data
      .pipe(
        combineLatestWith(
          this.store.observe(SOFTLINE_FEATURE_MDE_INVENTUR, InventurStore.getters.lager)
        ),
      )
      .subscribe(async ([{ inventur }, lager]) => {
        const lagerplatz = this.store.get(SOFTLINE_FEATURE_MDE_INVENTUR, InventurStore.getters.lagerplatz);

        if (inventur?.kopf) {
          this.store.commit(
            SOFTLINE_FEATURE_MDE_INVENTUR,
            InventurStore.mutations.setLager,
            inventur?.kopf?.lager
          );

          this.store.commit(
            SOFTLINE_FEATURE_MDE_INVENTUR,
            InventurStore.mutations.setLagerplatz,
            inventur?.kopf?.lagerplatz
          );

          this.store.commit(
            SOFTLINE_FEATURE_MDE_INVENTUR,
            InventurStore.mutations.setBelegdatum,
            inventur?.kopf?.belegdatum
          );
        }

        if (!lager) {
          await this.router.navigate(['/mde-inventur/eingabe-lager'])
        } else if (inventur?.lagerplatzEingabe && !lagerplatz) {
          await this.router.navigate(['/mde-inventur/eingabe-lagerplatz'])
        }
      });

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: InventurComponent,
        commands: [
          {
            name: 'Inventur',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-warehouse-full',
            isVisible: this.kopf$.pipe(map(o => !!o)),
            canExecute: this.kopf$.pipe(map(o => !!o)),
            routerLink: '/mde-inventur',
          },
        ]
      }
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_MDE_INVENTUR_BEWE,
      InventurBeweStore.actions.loadMany,
      { clear: true }
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      InventurComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_INVENTUR_ARTIKEL_DETAILS,
      InventurArtikelDetailStore.mutations.clear
    );

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }
}
