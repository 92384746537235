<h4 class="text-xl font-semibold mt-3 ml-3">
  {{ title | translate | interpolate: params }}
</h4>

<p *ngIf="subtitle" class="mb-1 mt-1 ml-3 mr-3 border-box color-light">
  {{ subtitle | translate | interpolate: params }}
</p>

<p *ngIf="content" class="mb-1 mt-1 ml-3 mr-3 border-box">
  <ng-container *ngTemplateOutlet="content; context: (params ?? null)"></ng-container>
</p>

<div class="c column mt-2 max-h-[80vh] overflow-y-auto">
  <div
    *ngFor="let option of options; let first = first"
    [class.separator-top]="!first"
    [class]="option?.class ?? ''"
    (click)="onClick(option)"
    class="c pt-4 pb-4 pl-3 pr-3 row border-box center-v"
    [ngClass]="{
    'cursor-pointer hover-highlight': !option.disabled,
    'cursor-not-allowed text-light': option.disabled,
    }"
  >
    <soft-icon *ngIf="option?.icon" class="mr-3" [name]="option?.icon"></soft-icon>
    <span class="soft-text">{{
      option?.label | translate | interpolate: params
    }}</span>
  </div>
</div>
