import { CarouselStrategy } from './carousel.strategy';

export interface CommonCarouselStrategyOptions {
  columns: number;
  jump: number;
}

const DEFAULT_COLUMNS = 1;
const DEFAULT_JUMP = 1;

export class CommonCarouselStrategy<T> extends CarouselStrategy<T> {
  private get columns(): number {
    return this.options?.columns ?? DEFAULT_COLUMNS;
  }

  private get jump(): number {
    return this.options?.jump ?? DEFAULT_JUMP;
  }

  constructor(protected options?: CommonCarouselStrategyOptions) {
    super();
  }

  getAnimation(index: number): any {
    return index;
  }

  getStart(items: T[]): T[] {
    const count = Math.min(items.length, this.columns);
    console.log(count);
    return items.slice(0, count);
  }

  canNext(items: T[], current?: T[]): boolean {
    if (!current?.length) return items.length > this.jump;

    const lastItem = current[current.length - 1];
    const lastIndex = items.indexOf(lastItem);

    return lastIndex + 1 < items.length;
  }

  getNext(items: T[], current: T[]): T[] {
    if (current.length === 0) return this.getStart(items);

    const firstItem = current[0];
    const firstIndex = items.indexOf(firstItem);
    let start = firstIndex + this.jump;
    let end = start + this.columns;

    if (end > items.length - 1) {
      end = items.length;
      start = end - this.columns;
    }

    const returnValue = items.slice(start, end);
    return returnValue;
  }

  canPrevious(items: T[], current?: T[]): boolean {
    if (!current?.length) return false;

    const firstItem = current[0];
    const firstIndex = items.indexOf(firstItem);

    return firstIndex > 0;
  }

  getPrevious(items: T[], current: T[]): T[] {
    if (current.length === 0) return this.getStart(items);

    const firstItem = current[0];
    const firstIndex = items.indexOf(firstItem);
    let start = firstIndex - this.jump;
    let end = start + this.columns;

    if (start < 0) {
      start = 0;
      end = start + this.columns;
    }

    const returnValue = items.slice(start, end);
    return returnValue;
  }
}
