import { Component, HostListener, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Store } from "@softline/core";
import { Router } from "@angular/router";
import {
    BackNavigationService,
    BlockingLoadingSpinnerComponent,
    handleRequestErrors,
    MengenEingabeComponent, PageHeaderComponent,
    SOFTLINE_FEATURE_TITLE, Step,
    StepHeaderComponent,
    TitleStore
} from "@softline/application";
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule
} from "@softline/ui-core";
import { SOFTLINE_FEATURE_INVENTUR } from "../../inventur.shared";
import { InventurStore } from "../../store";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import {
  ArtikelMenge,
  Lagerplatz,
  LagerplatzInhalt,
  LagerplatzSucheStore,
  SOFTLINE_FEATURE_LAGERPLATZ_SUCHE
} from "@softapps/lager/core";

@Component({
  selector: 'soft-mengen-auswahl',
  standalone: true,
    imports: [CommonModule, UiCoreModule, MengenEingabeComponent, StepHeaderComponent, BlockingLoadingSpinnerComponent, PageHeaderComponent],
  templateUrl: './mengen-auswahl.page.html',
  styleUrls: ['./mengen-auswahl.page.scss']
})
export class MengenAuswahlPage implements OnInit {

  readonly steps: Step[] = [
    {
      header: '#INVENTUR.PAGES.LAGERPLATZ_SCANNEN.TITLE',
      subHeader: '#INVENTUR.PAGES.LAGERPLATZ_SCANNEN.DESCRIPTION'
    },
    {
      header: '#INVENTUR.PAGES.ARTIKEL_AUSWAHL.TITLE',
      subHeader: '#INVENTUR.PAGES.ARTIKEL_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#INVENTUR.PAGES.MENGEN_AUSWAHL.TITLE',
      subHeader: '#INVENTUR.PAGES.MENGEN_AUSWAHL.DESCRIPTION'
    },
  ];

  loading$ = new BehaviorSubject<boolean>(false)
  lagerplatz$ = this.store.observe(SOFTLINE_FEATURE_INVENTUR, InventurStore.getters.lagerplatz);
  artikel$ = this.store.observe(SOFTLINE_FEATURE_INVENTUR, InventurStore.getters.artikel);

  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService) {
  }

  ngOnInit(): void {
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#INVENTUR.TITLE')
  }

  ngOnDestroy() {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '')
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/inventur/artikel-auswahl'])
  }

  async onMengeChange(menge: number | null | undefined): Promise<void> {
    const artikel = this.store.get(SOFTLINE_FEATURE_INVENTUR, InventurStore.getters.artikel);
    this.store.commit(SOFTLINE_FEATURE_INVENTUR, InventurStore.mutations.setArtikel, {...artikel, menge});
  }

  @HostListener('window:keydown.enter', ['$event'])
  async onSubmit(): Promise<void> {
    let lagerplatz = this.store.get(SOFTLINE_FEATURE_INVENTUR, InventurStore.getters.lagerplatz);
    const inhalt = this.store.get(SOFTLINE_FEATURE_INVENTUR, InventurStore.getters.artikel);
    this.loading$.next(true);
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_INVENTUR, InventurStore.actions.inventur, {
        lagerplatz: lagerplatz.lagerplatz,
        inhalt: [inhalt],
      })
      this.loading$.next(false);
      lagerplatz = await this.removeArtikel(lagerplatz, inhalt);
      await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#INVENTUR.MESSAGES.SUCCESS.KORREKTUR'
      )
      await this.navigateToNext(lagerplatz, inhalt);
    }
    catch (e) {
      this.loading$.next(false);
      handleRequestErrors(this.store, e);
    }
  }

  async removeArtikel(lagerplatz: LagerplatzInhalt, inhalt: ArtikelMenge): Promise<LagerplatzInhalt> {
    lagerplatz = {...lagerplatz, inhalt: lagerplatz.inhalt.filter(o => o.artikel.id !== inhalt.artikel.id)};
    this.store.commit(SOFTLINE_FEATURE_INVENTUR, InventurStore.mutations.setLagerplatz, lagerplatz);
    return lagerplatz;
  }

  async navigateToNext(lagerplatz: LagerplatzInhalt, inhalt: ArtikelMenge): Promise<void> {
    if(lagerplatz.inhalt.length > 0) {
      await this.router.navigate(['/inventur', 'artikel-auswahl'])
      return;
    }

    const lagerplaetze = this.store.get(SOFTLINE_FEATURE_LAGERPLATZ_SUCHE, LagerplatzSucheStore.getters.data);
    const result = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.choose<Lagerplatz>(), {
      title: '#LAGERPLATZ_CORE.DIALOGS.CHOOSE_LAGERPLATZ',
      options: lagerplaetze.map(o => ({label: o.lagerplatz, value: o})),
      dismiss: true,
    })
    if(result === "DISMISSED") {
      await this.router.navigate(['/inventur'])
      return;
    }

    this.loading$.next(true);
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_INVENTUR, InventurStore.actions.loadLagerplatz, result.barcode);
      this.loading$.next(false);
      await this.router.navigate(['/inventur/artikel-auswahl'])
    }
    catch (e) {
      this.loading$.next(false);
      handleRequestErrors(this.store, e);
    }
  }
}
