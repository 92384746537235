<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 class="text-xl font-semibold">Barcodeeingabe</h2>
  <div class="flex flex-col mt-4">
    <div class="soft-label top">Label</div>
    <soft-select formControlName="labelType">
      <soft-select-option *ngFor="let type of labelTypes" [value]="type">{{type}}</soft-select-option>
      <ng-container *ngIf="labelTypes?.length === 0">
        <soft-select-option-header
          [text]="'1 Dimensional'"
        ></soft-select-option-header>
        <soft-select-option [value]="'codabar'">Codabar</soft-select-option>
        <soft-select-option [value]="'code39'">Code39</soft-select-option>
        <soft-select-option [value]="'code93'">Code93</soft-select-option>
        <soft-select-option [value]="'code128'">Code128</soft-select-option>
        <soft-select-option [value]="'ean8'">EAN8</soft-select-option>
        <soft-select-option [value]="'ean13'">EAN13</soft-select-option>
        <soft-select-option [value]="'ean128'">EAN128</soft-select-option>
        <soft-select-option [value]="'itf'">ITF</soft-select-option>
        <soft-select-option [value]="'rss14'">RSS14</soft-select-option>
        <soft-select-option [value]="'upc_a'">UPC_A</soft-select-option>
        <soft-select-option [value]="'upc_e0'">UPC_E0</soft-select-option>
        <soft-select-option [value]="'upc_e1'">UPC_E1</soft-select-option>
        <soft-select-option [value]="'msi'">MSI</soft-select-option>

        <soft-select-option-header
          [text]="'2 Dimensional'"
        ></soft-select-option-header>
        <soft-select-option [value]="'aztec'">Aztec</soft-select-option>
        <soft-select-option [value]="'datamatrix'">Datamatrix</soft-select-option>
        <soft-select-option [value]="'maxicode'">MaxiCode</soft-select-option>
        <soft-select-option [value]="'pdf417'">PDF417</soft-select-option>
        <soft-select-option [value]="'rss_expanded'"
        >RSS Expanded</soft-select-option
        >
        <soft-select-option [value]="'micropdf'">MicroPDF</soft-select-option>
        <soft-select-option [value]="'qrcode'">QR Code</soft-select-option>
      </ng-container>
    </soft-select>
    <div class="soft-label top mt-2 required">Code</div>
    <soft-input formControlName="data"></soft-input>
    <hr class="soft-separator" />
    <div class="soft-label top">Verlauf</div>
    <soft-scan-history class="max-h-[40vh] overflow-auto" (scanSelect)="fillForm($event)"></soft-scan-history>
    <button type="submit"
            [disabled]="form.invalid"
            class="soft-button primary h-12 w-full mt-4">
      Bestätigen
    </button>
  </div>
</form>
