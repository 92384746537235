<ng-container [ngSwitch]="definition?.type">
  <div *ngSwitchCase="'container'">
    <soft-dynamic-skeleton
      *ngFor="let child of $any(definition)?.definitions"
      [definition]="child"
    >
    </soft-dynamic-skeleton>
  </div>
  <div
    *ngSwitchDefault
    class="skeleton"
    [ngClass]="definition?.skeleton ?? 'h-4 w-full'"
  ></div>
</ng-container>
