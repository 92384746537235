import {Component, Input, OnInit, Type} from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

@Component({
  selector: 'soft-page-header',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {

  @Input() overline?: string | null | undefined;
  @Input() title?: string | null | undefined;
  @Input() subtitle?: string | null | undefined;

  constructor() {}

  ngOnInit(): void {}
}
