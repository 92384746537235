import { Entity, isDefined, isDefinedNotEmpty, SelectStrategy, Store } from "@softline/core";
import { Injector } from "@angular/core";
import {
  FieldOkStrategyOptions,
  FieldOkStrategy,
  FieldOkInputParams
} from "../../field-ok/strategies/field-ok.strategy";
import { ModalStore, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import { FieldOkParameters } from "@softline/dynamic";
export class MultiselectFieldOkStrategy extends FieldOkStrategy<Entity[], Entity> {

  constructor(
    store: Store,
    injector: Injector,
    uuid: () => string,
    options: FieldOkStrategyOptions,
    protected selectStrategy: SelectStrategy<Entity[]>) {
    super(store, injector, uuid, options);
  }

  override async validate(input: string, options: FieldOkInputParams<[], object>): Promise<Entity[] | null> {
    if (input.length <= 0)
      return null;

    if(!isDefined(options.current))
      options = {...options, current: []}
    let result = await this.executeValidation(input, options.parameters);

    if(result === 'CANCELED')
      return null;

    if(typeof result === 'string') {
      const entries = result.split(',');
      const returnValue: any[] = []
      for (let entry of entries) {
        const validationResult = await this.executeValidation(entry, options.parameters, false);
        if(typeof validationResult === 'object' && isDefinedNotEmpty(validationResult))
          returnValue.push(validationResult);
      }
      return this.selectStrategy.select(returnValue, options.current);
    }
    else if (result && !Array.isArray(result))
      return this.selectStrategy.select([result], options.current);

    result = await this.openDialog(this.options.config.defaultDataView, options);
    if(result === 'DISMISSED' || result === 'CANCELED')
      return options.current ?? [];

    if(result && typeof result !== "string")
      return this.selectStrategy.select(result, options.current);
    return options.current ?? [];
  }

  override async resolve(value: Entity[]): Promise<void> {
    if(!this.modalId)
      return;
    const result: Entity[] = [];
    for (let item of value) {
      const fieldOkResult = await this.loadResult(item.id) as Entity;
      if(fieldOkResult)
        result.push(fieldOkResult);
    }

    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.resolve(),
      {id: this.modalId, result }
    )
  }

  protected override getQueryParams(input: string | null, extraQuery?: object, multiValued?: boolean): FieldOkParameters {
    return super.getQueryParams(input, extraQuery, isDefined(multiValued) ? multiValued : true);
  }
}
