export const environment = {
  production: true,
  connection: {
    name: 'Produktiv',
    host: 'https://mde.inn-traun.lagerhaus.at',
    port: 443,
    basePath: '/restapi/api'
  },
  theme: undefined,
  androidUpdateRepository: ''
};
