import { Definition } from "./definitions";
import { ComponentRef, EventEmitter, Type } from "@angular/core";
import { EntityInputStrategy } from "@softline/ui-core";
import { Dictionary } from "@softline/core";

export interface FieldOk {
  id: number;
}

export interface FieldOkParameters<T = object> {
  filter: string | null;
  multiValued: boolean;
  parameters: T;
  maxAbfrageResults?: number;
}

export interface FieldOkQueryResult<T = object> {
  list: T[];
  definition: Definition | null;
  multipleValueName?: string;
}

export interface FieldOkValidationResult<T = object> {
  value?: T;
  list?: T[];
  definition: Definition | null;
}

export interface FieldOkConfig {
  name: string;
  priority?: number;
  template?: string | Type<FieldOkTemplateComponent<any>>;
  type?: 'query' | 'multi' | 'default' |  string;

  defaultView?: string | string[];
  defaultDataView?: string | string[];

  views: FieldOkView<any>[];
  autoQuery?: boolean;
}

export interface FieldOkTemplateComponent<T> {
  item: T
}

export interface FieldOkViewComponent<TConfig extends object> {
  config: TConfig
}

export interface FieldOkView<TConfig extends object> {
  name: string;
  title: string;
  subtitle?: string;
  component: Type<FieldOkViewComponent<TConfig>>;
  config: TConfig;
}

export interface FieldOkItemComponent<T> {
  item: T
}

export interface FieldOkQueryComponent<T> {
  query: T
  queryChange: (query: T) => void
  requestSubmit: EventEmitter<void>;
  submit: (query: T) => void
}

