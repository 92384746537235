<form
  [formGroup]="form"
  class="soft-form"
  (ngSubmit)="onSubmit(form.value)"
  (submit)="onNativeSubmit($event)"
>
  <span class="text-xl, font-bold">{{
    '#DYNAMIC.FILTER.CHANGE' | translate
  }}</span>
  <div
    [ngClass]="definition.class | classRule: form.value"
    class="soft-dynamic-form-field mt-4"
  >
    <ng-container
      *ngIf="definition.visible | booleanRule: form.value:true"
      softDynamicFormField
      [definition]="definition"
      [form]="form"
    ></ng-container>
  </div>
  <div class="mt-4">
    <button
      type="submit"
      class="soft-button primary w-full h-16"
      [disabled]="!(form | isValid)"
    >
      {{ '#DYNAMIC.FILTER.SUBMIT' | translate }}
    </button>
  </div>
</form>
