<h2 class="mt-3 px-4 font-semibold text-xl mb-2">Bewegungen {{ stockLocation?.stock?.title ?? stockLocation?.stock?.name }}</h2>

<soft-tab-group [activatedIndex]="(tabIndex$ | async) ?? 0" (activatedIndexChange)="tabIndexChanged($event)">
  <soft-tab [title]="'Aufträge'"></soft-tab>
  <soft-tab [title]="'Kommissionierscheine'"></soft-tab>
  <soft-tab [title]="'Bestellungen'"></soft-tab>
</soft-tab-group>

<div class="flex justify-between items-center bg-gray-100 border border-solid border-separator mx-4 mt-3 py-1 rounded">
  <span class="w-2/4 pl-1">Daten</span>
  <span class="w-1/4 text-right">Menge</span>
  <span class="w-1/4 pr-1 text-right justify-end">Offen</span>
</div>

<div class="flex flex-col overflow-y-auto max-h-[65vh] mt-2">
  <ng-container *ngIf="(loading$ | async); else content">
    <div class="flex h-14 skeleton mx-4 rounded mb-2" *softRepeat="2"></div>
  </ng-container>

  <ng-template #content>
    <ng-container *ngIf="summedBewegungen$ | async as bewegungen">
      <ng-container *ngIf="bewegungen && (bewegungen?.length || 0) > 0; else noBewegungenFound">
        <cdk-accordion class="flex flex-col gap-2 mb-2 pb-2" [multi]="true" *ngIf="summedBewegungen$ | async as bewegungen">
          <cdk-accordion-item
            #accordionItem="cdkAccordionItem"
            *ngFor="let bewegung of (bewegungen || []); let i = index; let last = last;"
            role="button"
            tabindex="0"
            [disabled]="(bewegung.childs ?? []).length < 1"
            [attr.id]="'accordion-header-' + i"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'accordion-body-' + i"
            class="flex flex-col w-full px-4">

            <div class="flex flex-row w-full justify-between items-center px-2 w-full py-2 border border-solid border-primary-200 bg-primary-100 "
                 [ngClass]="accordionItem.expanded ? 'rounded-t' : 'rounded'"
                 (click)="accordionItem.toggle()">
              <div class="flex flex-col w-2/4">
                <span class="font-semibold">{{ bewegung?.belegart?.belegart }} {{ bewegung?.belegnummer }}</span>
                <span class="font-normal text-sm mt-1">{{ bewegung?.belegdatum | date:'dd.MM.YYYY' }}<ng-container *ngIf="bewegung?.liefertermin">&nbsp;/&nbsp;{{ bewegung?.liefertermin | date:'dd.MM.YYYY' }}</ng-container></span>
              </div>

              <div class="flex flex-row items-center w-2/4">
                <div class="flex justify-end self-end text-right w-full">{{ bewegung?.sum?.menge | number:'1.2-2' }} {{ bewegung?.sum?.einheit }}</div>
                <div class="flex gap-2 text-right self-end justify-end items-center w-full">
                  {{ bewegung?.sum?.mengeOffen | number:'1.2-2'}} {{ bewegung?.sum?.einheit }}
                  <!-- <div class="text-lighter" *ngIf="(bewegung.childs ?? []).length > 0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="!accordionItem.expanded">
                      <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-light" *ngIf="accordionItem.expanded">
                      <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
                    </svg>
                  </div> -->
                </div>
              </div>
            </div>

            <ng-container *ngTemplateOutlet="accordionTemplate; context: { accordionItem: accordionItem, bewegung: bewegung, last: last }"></ng-container>
          </cdk-accordion-item>
        </cdk-accordion>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #noBewegungenFound>
    <div class="text-base p-12 text-center text-light">
      Keine Bewegungen gefunden
    </div>
  </ng-template>
</div>


<ng-template #accordionTemplate let-bewegung="bewegung" let-accordionItem="accordionItem" let-last="last">
  <div
    class="flex flex-col bg-primary-100  border-solid border-r border-l border-primary-200"
    [style.display]="accordionItem?.expanded ? 'flex' : 'none'"
    [ngClass]="[accordionItem?.expanded ? 'rounded-b' : 'rounded-none', last ? 'border-b' : '']"
  >
    <cdk-accordion-item
      #innerAccordionItem="cdkAccordionItem"
      *ngFor="let bewegung of (bewegung?.childs || []); let i = index"
      role="button"
      tabindex="0"
      [expanded]="false"
      [disabled]="(bewegung.childs ?? []).length < 1"
      [attr.id]="'inner-accordion-header-' + i"
      [attr.aria-expanded]="innerAccordionItem.expanded"
      [attr.aria-controls]="'inner-accordion-body-' + i"
      class="flex flex-col w-full py-2 px-1">
      <div class="flex items-center">
        <span class="w-2/4 pl-1 text-sm mt-1">{{ bewegung?.belegdatum | date:'dd.MM.YYYY' }}<ng-container *ngIf="bewegung?.liefertermin">&nbsp;/&nbsp;{{ bewegung?.liefertermin | date:'dd.MM.YYYY' }}</ng-container></span>
        <span class="w-1/4 text-right">{{ bewegung?.menge_lg | number:'1.2-2' }} {{ bewegung?.einheit_lg?.arteh }}</span>
        <span class="w-1/4 pr-1 text-right justify-end">{{ bewegung?.menge_offen_lg | number:'1.2-2' }} {{ bewegung?.einheit_lg?.arteh }}</span>
      </div>

      <ng-container *ngIf="bewegung && (bewegung?.childs?.length ?? 0) > 0">
        <ng-container *ngTemplateOutlet="accordionTemplate; context: { bewegung: bewegung, accordionItem: innerAccordionItem }"></ng-container>
      </ng-container>
    </cdk-accordion-item>
  </div>
</ng-template>
