import {Rule} from '../data/rules';
import { Injectable, Injector } from "@angular/core";
import { CustomRuleResolver } from "../dynamic.shared";
import { Store } from "@softline/core";
import { AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION } from "@softline/auth";

export interface PermissionRule {
  name: string;
  permission: string;
  not?: boolean;
}

@Injectable()
export class PermissionRuleResolver implements CustomRuleResolver<PermissionRule>{
  name = 'permission';

  constructor(private store: Store) {
  }

  resolve(rule: PermissionRule, value: any): boolean {
    return this.store.get(SOFTLINE_FEATURE_AUTHORIZATION, AuthorizationStore.getters.authorized, rule.permission) !== (rule.not ?? false)
  }
}
