import * as CollectionStore from './collection.store';
import { Entity } from '../types/entity';
import { StoreFeature } from '../../../store';
import {
  createGetter,
  createMutation,
  mutate,
  select,
} from '../../../factories';

export interface State<T extends Entity = Entity>
  extends CollectionStore.State<T> {
  selected: (string | number)[];
}

export class Store<T extends Entity = Entity> {
  mutations = {
    select: createMutation<State<T>, T | number | string>('select'),
    toggle: createMutation<State<T>, T | number | string>('toggle'),
    unselect: createMutation<State<T>, T | number | string>('unselect'),
    setSelected: createMutation<State<T>, (number | string)[]>('set selected'),
  };

  getters = {
    selected: createGetter<State<T>, T[]>('selected'),
    selectedIds: createGetter<State<T>, (number | string)[]>('selectedIds'),
  };

  feature: Partial<StoreFeature<State<T>>> = {
    initialState: {
      selected: [],
    } as unknown as State<T>,
    mutations: [
      mutate(this.mutations.select, ({ state, params }) => {
        if (typeof params === 'object') params = params.id;
        if (state.ids.indexOf(params) === -1)
          throw new Error(
            `SelectStore - select: Cannot select id '${params}' (not in store)`
          );

        const selected = [...state.selected];
        if (selected.indexOf(params) === -1) selected.push(params);
        return { ...state, selected };
      }),
      mutate(this.mutations.setSelected, ({ state, params }) => {
        for (const id of params) {
          if (state.ids.indexOf(id) === -1)
            throw new Error(
              `SelectStore - set selected: Cannot select id '${params}' (not in store)`
            );
        }
        return { ...state, selected: params };
      }),
      mutate(this.mutations.unselect, ({ state, params }) => {
        if (typeof params === 'object') params = params.id;
        const selected = [...state.selected];
        const index = selected.indexOf(params);
        if (index === -1)
          throw new Error(
            `SelectStore - unselect: Cannot unselect id '${params}' (not selected)`
          );

        if (index > -1) selected.splice(index);
        return { ...state, selected };
      }),
      mutate(this.mutations.toggle, ({ state, params }) => {
        if (typeof params === 'object') params = params.id;
        if (state.ids.indexOf(params) === -1)
          throw new Error(
            `SelectStore - toggle: Cannot select id '${params}' (not in store)`
          );

        const selected = [...state.selected];
        const index = selected.indexOf(params);
        if (index === -1) selected.push(params);
        else selected.splice(index);
        return { ...state, selected };
      }),

      mutate<State<T>, T>(
        CollectionStore.mutations.remove,
        ({ state, params }) => {
          const index = state.selected.indexOf(params.id);
          if (index === -1) return state;

          const selected = [...state.selected];
          selected.splice(index);
          return { ...state, selected };
        }
      ),
      mutate<State<T>, T[]>(
        CollectionStore.mutations.removeMany,
        ({ state, params }) => {
          const selected = [...state.selected];
          for (const entity of params) {
            const index = state.selected.indexOf(entity.id);
            if (index > -1) selected.splice(index);
          }
          return { ...state, selected };
        }
      ),
      mutate<State<T>, T>(CollectionStore.mutations.clear, ({ state }) => {
        return { ...state, selected: [] };
      }),
    ],
    getters: [
      select<State<T>, undefined, T[]>(
        this.getters.selected,
        ({ state, params }) => state.selected.map((o) => state.entities[o] as T)
      ),
      select<State<T>, undefined, (number | string)[]>(
        this.getters.selectedIds,
        ({ state, params }) => state.selected
      ),
    ],
  };
}

export function create<T extends Entity>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const feature = instance.feature;
