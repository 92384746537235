import {Command} from '@softline/application';
import {Store} from '@softline/core';
import {Auftrag} from '../data/auftrag';
import {MessageBarStore, ModalStore, SOFTLINE_FEATURE_MESSAGE_BAR, SOFTLINE_FEATURE_MODAL} from '@softline/ui-core';
import {SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE} from '../auftrag.shared';
import * as WerkstattAuftraegeStore from '../store/werkstatt-auftraege.store';

export class SignatureCommand implements Command {
  icon = 'fa-regular fa-signature';
  name = '#WORKSHOP.TASK_DETAILS.COMMANDS.SIGNATURE';
  class = 'menu action-menu action-menu-top';
  execute = () => this.showSignatureDialog();

  constructor(private store: Store, private task: () => Auftrag) {}

  private showSignatureDialog = async (): Promise<void> => {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.sign,
      { dismiss: true }
    );

    if (!result || result === 'DISMISSED')
      return;

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
        WerkstattAuftraegeStore.actions.sign,
        {
          id: this.task().id,
          signature: { files: [result] },
        }
      )
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title: '#WORKSHOP.MESSAGES.SUCCESS.TITLE',
          message: '#WORKSHOP.MESSAGES.SUCCESS.SIGN'
        }
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        {
          title: '#WORKSHOP.DIALOGS.ERROR_SUBJECT',
          message: '#WORKSHOP.DIALOGS.SIGNATURE.ERROR',
          actions: [{
            title: '#WORKSHOP.DIALOGS.TRY_AGAIN',
            type: 'link',
          }],
        }
      );
    }
  }
}
