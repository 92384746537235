import { inject, Injectable, Type } from "@angular/core";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_MODAL } from "./modal.shared";
import * as ModalStore from "./modal.store";
import { Modal } from "./data/modal";

type Constructor<T> = new(...args: any[]) => T;

export const WithModal = <T extends Constructor<{}>, R>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class ModalMixin extends Base implements Modal<R> {

    close!: (result: R) => void;

    constructor(...args: any[]) {
      super(...args);
    }

    registerCloseHandler(handler: (result: R) => void) {
      this.close = handler;
    }

  }
  return ModalMixin;
}
