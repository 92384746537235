<div class="flex flex-row flex-nowrap h-full">
  <button class="soft-button w-full flex flex-row flex-nowrap justify-center items-center"
          [disabled]="disabled()"
          [ngClass]="[type()]"
          (click)="action.emit($event)">
    <ng-content></ng-content>
  </button>
  <button class="soft-button ml-[1px]"
          [ngClass]="[type()]"
          [disabled]="splitDisabled()"
          (click)="onShowOptions()">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 fill-current" viewBox="0 0 512 512">
      <path d="M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"/></svg>
  </button>
</div>
