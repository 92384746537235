import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {RemoteConfigResolver} from '@softline/application';
import {EtikettendruckResolver} from './resolver/etikettendruck-resolver.service';
import {SOFTLINE_PERMISSION_MDE_ETIKETTENDRUCK} from './etikettendruck.shared';

export const etikettendruckRoutes: Routes = [
  {
    path: 'mde-etikettendruck',
    title: 'Etikettendruck',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/etikettendruck.component').then(
            (o) => o.EtikettendruckComponent
          ),
        children: [
          {
            path: 'artikel-erfassen',
            loadComponent: () => import('./pages/artikel-erfassen/artikel-erfassen.component').then(
              c => c.ArtikelErfassenComponent
            ),
            loadChildren: () => [
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt1/schritt1.component').then(
                  c => c.Schritt1Component
                ),
                path: 'suche'
              },
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt2/schritt2.component').then(
                  c => c.Schritt2Component
                ),
                path: ':idartstamm'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/mde-etikettendruck/artikel-erfassen/suche'
              }
            ]
          },
          {
            path: 'auswahl',
            children: [
              {
                path: 'kategorie',
                loadComponent: () => import('./pages/kategorie-auswahl/kategorie-auswahl.component').then(
                  o => o.KategorieAuswahlComponent
                )
              },
              {
                path: 'arten',
                loadComponent: () => import('./pages/etikettenarten/etikettenarten.component').then(
                  o => o.EtikettenartenComponent
                )
              },
              {
                path: '**',
                redirectTo: '/mde-etikettendruck/auswahl/kategorie'
              },
            ]
          },
          {
            path: '',
            loadComponent: () => import('./pages/erfassung/erfassung.component').then(
              o => o.ErfassungComponent
            )
          },
          {
            path: '**',
            redirectTo: '/mde-etikettendruck'
          },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_MDE_ETIKETTENDRUCK },
    resolve: { config: RemoteConfigResolver, etikettendruck: EtikettendruckResolver },
    providers: [],
  },
];
