import { ModuleWithProviders, NgModule } from "@angular/core";
import { Store } from "@softline/core";
import { QueryHistoryService, TemplateService } from "@softline/application";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { default as de } from "../i18n/de.json";
import { SoftappsTemplateService } from "./templates/services/softapps-template.service";
import { SoftappsQueryHistoryService } from "./templates/services/softapps-query-history.service";

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
  ],
  exports: [
  ],
})
export class SoftappsCoreModule {
  static forRoot(): ModuleWithProviders<SoftappsCoreRootModule> {
    return {
      ngModule: SoftappsCoreRootModule,
      providers: [
        { provide: TemplateService, useClass: SoftappsTemplateService },
        { provide: QueryHistoryService, useClass: SoftappsQueryHistoryService }
      ],
    };
  }
}


@NgModule({
  imports: [
    SoftappsCoreModule,
  ],
  exports: [SoftappsCoreModule],
})
export class SoftappsCoreRootModule {
  constructor(store: Store) {
    store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
      module: 'softapps-core',
      language: 'de',
      translations: de,
    });
  }
}
