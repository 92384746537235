import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MessageBarStore, Modal, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule, Validators} from '@softline/ui-core';
import {Auftrag} from '../../data/auftrag';
import { DynamicModule, FieldOkResultConverter } from "@softline/dynamic";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT} from '../../auftrag.shared';
import {handleRequestErrors} from '@softline/application';
import {WerkstattArbeitsberichtStore} from '../../store/werkstatt-arbeitsbericht.store'
import moment from 'moment';
import {Aktivitaet} from '../../data/aktivitaet';

@Component({
  selector: 'soft-start-arbeitsbericht-dialog',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DynamicModule, UiCoreModule],
  templateUrl: './start-arbeitsbericht-dialog.component.html',
  styleUrls: ['./start-arbeitsbericht-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartArbeitsberichtDialogComponent implements Modal<null> {

  taetigkeitConverter = new FieldOkResultConverter({ 'bezeichnung': 'title', 'id': 'id', 'taetigkeit': 'name' });

  auftrag!: Auftrag;
  close!: (result: null) => void;

  readonly form = new FormGroup({
    activity: new FormControl<Aktivitaet | undefined>(undefined, Validators.required()),
    description: new FormControl<string | undefined>(undefined)
  });

  constructor(private store: Store, private fb: FormBuilder) { }

  registerCloseHandler(handler: (result: null) => void) {
    this.close = handler;
  }

  async onSubmit(): Promise<void> {
    if (this.form.invalid) { return; }
    await this.startActivity();
  }

  private async startActivity(): Promise<void> {
    const { activity, description } = this.form.getRawValue();

    if (!activity)
      return;

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
        WerkstattArbeitsberichtStore.actions.start,
        {
          id: this.auftrag.id + '',
          arbeitsbericht: {
            id: '',
            activity,
            description: description ?? '',
            orderId: this.auftrag.id,
            date: moment().toISOString(true)
          }
        }
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        { title: 'Die Tätigkeitserfassung wurde erfolgreich gestartet!' }
      );

      this.close(null);
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }
}
