import { HttpParams } from '@angular/common/http';
import { Dictionary } from '../../../types/dictionary';
import { isDefined } from '../../../functions/is-defined.function';
import { base64Encode } from '../../../functions/base64.function';

export interface HttpResourceLocation {
  url: string;
  queryParams?: HttpParams;
}

export interface UrlLocationParameters {
  url: string;
  queryParams?: Dictionary<unknown>;
  pathParams?: Dictionary<unknown>;
}

export interface HttpLocationParameters {
  host: string;
  port?: number;
  path?: string | string[];
  queryParams?: Dictionary<unknown>;
  pathParams?: Dictionary<unknown>;
}

export function createHttpResourceLocation(
  params: string | UrlLocationParameters | HttpLocationParameters
): HttpResourceLocation {
  if (typeof params === 'string') return { url: params };

  let url: string | undefined;
  if (isHttpLocationParameters(params))
    url = getUrl(
      params.host,
      params.port,
      isDefined(params.path)
        ? typeof params.path === 'string'
          ? [params.path]
          : params.path
        : []
    );
  else if (isUrlLocationParameters(params)) url = params.url;
  else throw new Error('HttpResourceLocationFactory: No location!');

  let queryParams: HttpParams | undefined;
  if (params.pathParams) url = replacePathParams(url, params.pathParams);
  if (params.queryParams) queryParams = getQueryParams(params.queryParams);

  return { url, queryParams };
}

function getUrl(
  host: string,
  port: number | undefined,
  paths: string[]
): string {
  let url = host.replace(/^\/+|\/+$/g, '');
  if (port) url += `:${port}`;
  for (const path of paths.filter(isDefined))
    url += `/${path.replace(/^\/+|\/+$/g, '')}`;

  return url;
}

function replacePathParams(
  url: string,
  pathParams: Dictionary<unknown>
): string {
  for (const [key, value] of Object.entries(pathParams)) {
    const convertedValue = convertToPathString(value);
    if (convertedValue) url = url.replace(`{{${key}}}`, convertedValue);
  }
  return url;
}

function getQueryParams(queryParams: Dictionary<unknown>): HttpParams {
  let httpParams = new HttpParams();
  for (const [key, value] of Object.entries(queryParams)) {
    const convertedValue = convertToPathString(value);
    if (convertedValue) httpParams = httpParams.append(key, convertedValue);
  }
  return httpParams;
}

function convertToPathString(value: unknown): string | undefined {
  switch (typeof value) {
    case 'string':
      return value;
    case 'number':
    case 'bigint':
    case 'boolean':
      return '' + value;
    case 'object':
      return base64Encode(JSON.stringify(value));
    case 'undefined':
      return undefined;
    default:
      throw new Error(`createLocation: Unknown parameter value ${value}`);
  }
}

export function isUrlLocationParameters(
  params: unknown
): params is UrlLocationParameters {
  return (params as UrlLocationParameters)?.url !== undefined;
}

export function isHttpLocationParameters(
  params: unknown
): params is HttpLocationParameters {
  return (params as HttpLocationParameters)?.host !== undefined;
}
