import { Component, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { SOFTLINE_FEATURE_LAGERSTAND } from "../../lagerstand.shared";
import { LagerstandStore } from "../../store";
import {
    BackNavigable,
    BackNavigationService, BlockingLoadingSpinnerComponent,
    handleRequestErrors,
    ItemScanStore, PageHeaderComponent,
    Refreshable,
    RefreshService,
    ScannerStore,
    SOFTLINE_FEATURE_ITEM_SCAN,
    SOFTLINE_FEATURE_SCANNER,
    SOFTLINE_FEATURE_TITLE, Step, StepHeaderComponent,
    TitleStore
} from "@softline/application";
import { defined, isDefined, Store } from "@softline/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  asapScheduler,
  BehaviorSubject,
  combineLatestWith,
  filter,
  map, Observable,
  observeOn,
  skip,
  Subscription,
  switchMap
} from "rxjs";
import { Artikel, ArtikelMenge, Lagerplatz } from "@softapps/lager/core";
import { I18nModule } from "@softline/ui-core";

@Component({
  selector: 'soft-lagerstand-ergebniss',
  standalone: true,
    imports: [CommonModule, BlockingLoadingSpinnerComponent, I18nModule, StepHeaderComponent, PageHeaderComponent],
  templateUrl: './lagerstand-ergebnis.page.html',
  styleUrls: ['./lagerstand-ergebnis.page.scss']
})
export class LagerstandErgebnisPage implements OnInit, OnDestroy, BackNavigable, Refreshable {

  private routeSubscription?: Subscription;
  private scannerSubscription?: Subscription;

  steps: Step[] = [
    {
      header: '#LAGERSTAND.PAGES.ARTIKEL_EINGABE.TITLE',
      subHeader: '#LAGERSTAND.PAGES.ARTIKEL_EINGABE.DESCRIPTION',
      routerLink: ['lagerstand']
    },
    {
      header: '#LAGERSTAND.PAGES.ERGEBNIS.TITLE',
      subHeader: '#LAGERSTAND.PAGES.ERGEBNIS.DESCRIPTION'
    }
  ]

  loading$ = new BehaviorSubject<boolean>(false);
  lagerstandArtikel$: Observable<{lagerplatz: Lagerplatz, artikel: ArtikelMenge}[]> = this.route.paramMap.pipe(
      map(o => o.get('id')),
      filter((o): o is string => isDefined(o)),
      combineLatestWith(this.store.observe(SOFTLINE_FEATURE_LAGERSTAND, LagerstandStore.getters.lagerstand)),
      map(([id, lagerplatzinhalt]) => {
        console.log(lagerplatzinhalt);
        return lagerplatzinhalt?.map(o => ({
          lagerplatz: o.lagerplatz,
          artikel: o.inhalt.find(o => o.artikel?.id === +id)
          }))
          .filter(o => !!o.artikel)
          .map(o => o as {lagerplatz: Lagerplatz, artikel: ArtikelMenge})
      })
    );

  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService,
              private refreshService: RefreshService,
              private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.refreshService.add(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#LAGERSTAND.TITLE');

    this.routeSubscription = this.route.paramMap
      .subscribe(async o => {
          const id = o.get('id');
          await this.loadLagerstand(id);
      });

    this.scannerSubscription = this.store.observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
      .pipe(
        skip(1),
        observeOn(asapScheduler)
      )
      .subscribe(async scan => {
        if(!scan || this.loading$.value)
          return;
        this.loading$.next(true);
        try {
          const artikel = await this.store.dispatch(SOFTLINE_FEATURE_ITEM_SCAN, ItemScanStore.actions.load, scan);
          this.loading$.next(false);
          if(artikel)
            await this.router.navigate(['lagerstand', artikel.artikel.id]);
        }
        catch (e) {
          handleRequestErrors(this.store, e)
        }
        this.loading$.next(false);
      })
  }

  ngOnDestroy(): void {
    if (this.scannerSubscription && !this.scannerSubscription.closed)
      this.scannerSubscription.unsubscribe();
    this.scannerSubscription = undefined;
    if (this.routeSubscription && !this.routeSubscription.closed)
      this.routeSubscription.unsubscribe();
    this.routeSubscription = undefined;
  }

  async refresh(): Promise<void> {
    try {
      const id = this.route.snapshot.paramMap.get('id');
      await this.store.dispatch(SOFTLINE_FEATURE_LAGERSTAND, LagerstandStore.actions.loadLagerstand, '' + id)
    }
    catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['lagerstand']);
  }

  private async loadLagerstand(id: number | string | null): Promise<void> {
    try {
      this.loading$.next(true);
      await this.store.dispatch(SOFTLINE_FEATURE_LAGERSTAND, LagerstandStore.actions.loadLagerstand, '' + id)
    }
    catch (e) {
      handleRequestErrors(this.store, e);
    }
    this.loading$.next(false);
  }

}
