import { computed, inject, Injectable, OnDestroy, OnInit } from "@angular/core";
import {
  CollectionStore, Entity, Filter,
  FilterCollectionStore,
  FilterService, Sort,
  SortCollectionStore,
  SortService,
  Store
} from "@softline/core";
import { BelegStore, SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG } from "@softapps/wws/gesamtabfrage";

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithSort = <T extends Constructor<{}>>(featureName: string, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class SortMixin extends Base {

    store = inject(Store);
    sortService = inject(SortService);

    sort = this.store.signal(
      featureName,
      SortCollectionStore.getters.sort
    )

    constructor(...args: any[]) {
      super(...args);
    }

    async setSort(sort: Sort | null): Promise<void> {
      this.store.commit(featureName,
        SortCollectionStore.mutations.setSort,
        sort
      )
    }
  }
  return SortMixin;
}
