import {
  Directive,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  input,
  Output,
  Renderer2
} from "@angular/core";
import {Sort} from "@softline/core";
import {DOCUMENT} from "@angular/common";

@Directive({
  selector: '[softSortHeader]',
  standalone: true,
})
export class SortHeaderDirective {

  private arrow: any;

  property = input.required<string>({alias: 'softSortHeader'});

  sort= input<Sort | null>();
  @Output() sortChange = new EventEmitter<Sort | null>();

  sortEffect = effect(() => {
    if(!this.property())
      return;

    const sort = this.sort();
    if(this.arrow)
      this.renderer.removeChild(this.el.nativeElement, this.arrow);
    if(sort && sort.property === this.property()) {
      const arrow = sort.direction === 'asc' ? ' ▲' : ' ▼';
      this.arrow = this.document.createElement('span');
      this.arrow.innerText = arrow;
      this.renderer.appendChild(this.el.nativeElement, this.arrow);
    }
  });

  propertyEffect = effect(() => {
    if(this.property()){
      this.renderer.addClass(this.el.nativeElement, 'hover:underline')
      this.renderer.addClass(this.el.nativeElement, 'cursor-pointer')
    }
    else {
      this.renderer.removeClass(this.el.nativeElement, 'hover:underline')
      this.renderer.removeClass(this.el.nativeElement, 'cursor-pointer')
    }
  });


  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) { }

  @HostListener('click') onMouseEnter() {
    let sort = this.sort()
    if(sort && sort.property === this.property())
      sort = {property: this.property(), direction: sort.direction === 'asc' ? 'desc' : 'asc'} as Sort;
    else
      sort = {property: this.property(), direction: 'asc'} as Sort;
    this.sortChange.emit(sort);
  }
}
