import { LabelType } from '../data/label-type';
import { Scan } from '../data/scan';

export abstract class ScannerService {
  public isAvailable = false;

  async init(): Promise<void> {
    this.isAvailable = true;
  }

  async activate(): Promise<void> {}
  async deactivate(): Promise<void> {}

  abstract scan(labelType?: LabelType | LabelType[]): Promise<Scan>;
  abstract cancel(): Promise<void>;
}
