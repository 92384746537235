import {
  ActionStore,
  createAction,
  createGetter,
  createMutation,
  KeyStore,
  mutate,
  on,
  ParamsStore, select,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore
} from "@softline/core";
import { FieldOkParameters } from './data/field-ok';
import { SOFTLINE_STORE_FIELD_OK_SERVICE } from './dynamic.shared';
import { FieldOkService } from './services/field-ok.service';

export interface QueryActionParameters<TParams> {
  params: FieldOkParameters<TParams>;
  token?: string;
}

export interface LoadActionParameters<TParams> {
  id: string | number;
  params: FieldOkParameters<TParams>;
  token?: string;
}

export interface State<T extends object = object, TList extends object = object>
  extends SubscriptionStore.State,
    ActionStore.State,
    KeyStore.State,
    ParamsStore.State {
  value?: T | null;
  list?: TList[];
}

export class Store<
  T extends object = object,
  TList extends object = object,
  TParams extends object = object
> {
  constructor() {}

  mutations = {
    action: ActionStore.mutations,
    subscription: SubscriptionStore.mutations,
    setValue: createMutation<State<T, TList>, T | null>('set value'),
    setList: createMutation<State<T, TList>, TList[]>('set list'),
    reset: createMutation<State<T, TList>>('reset'),
  };
  actions = {
    ...SubscriptionStore.actions,
    load: createAction<State<T>, LoadActionParameters<TParams>, T>('load'),
    query: createAction<State<T>, QueryActionParameters<TParams>, TList[]>(
      'query'
    ),
    validate: createAction<
      State<T>,
      QueryActionParameters<TParams>,
      { value?: T; list?: TList[] } | null
    >('validate'),
  };
  getters = {
    action: ActionStore.getters,
    subscription: SubscriptionStore.getters,
    loading: createGetter<State<T>, boolean>('loading'),
    loaded: createGetter<State<T>, boolean>('loaded'),
    list: createGetter<State<T>, TList[]>('list'),
  };

  feature: StoreFeature<State<T, TList>> = {
    initialState: {
      ...ActionStore.feature.initialState,
      ...SubscriptionStore.feature.initialState,
      ...KeyStore.feature.initialState,
      ...ParamsStore.feature.initialState,
    },
    mutations: [
      ...ActionStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
      ...KeyStore.feature.mutations,
      ...ParamsStore.feature.mutations,
      mutate(this.mutations.setValue, ({ state, params }) => ({
        ...state,
        value: params,
      })),
      mutate(this.mutations.setList, ({ state, params }) => ({
        ...state,
        list: params,
      })),
      mutate(this.mutations.reset, ({ state }) => ({
        ...state,
        ...this.feature.initialState,
      })),
    ],
    actions: [
      ...ActionStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      on(
        this.actions.load,
        async ({ params, injector, featureName, commit }) => {
          const service = injector.get(
            SOFTLINE_STORE_FIELD_OK_SERVICE
          ) as FieldOkService<T, TList, TParams>;
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const subscription$ = SubscriptionStore.handleSubscriptionState(
            service.load(params.params, params.id),
            featureName,
            commit,
            token
          );
          const result = await ActionStore.handleObservableActionState(
            subscription$,
            featureName,
            commit,
            this.actions.load.name,
            token
          ).toPromise();
          commit(featureName, this.mutations.setValue, result);
          return result;
        }
      ),
      on(
        this.actions.query,
        async ({ params, injector, featureName, commit }) => {
          const service = injector.get(
            SOFTLINE_STORE_FIELD_OK_SERVICE
          ) as FieldOkService<T, TList, TParams>;
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const subscription$ = SubscriptionStore.handleSubscriptionState(
            service.query(params.params),
            featureName,
            commit,
            token
          );
          const result = await ActionStore.handleObservableActionState(
            subscription$,
            featureName,
            commit,
            this.actions.query.name,
            token
          ).toPromise();
          commit(featureName, this.mutations.setList, result);
          return result;
        }
      ),
      on(
        this.actions.validate,
        async ({ params, injector, featureName, commit }) => {
          const service = injector.get(
            SOFTLINE_STORE_FIELD_OK_SERVICE
          ) as FieldOkService<T, TList, TParams>;
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const subscription$ = SubscriptionStore.handleSubscriptionState(
            service.validate(params.params),
            featureName,
            commit,
            token
          );
          const result = await ActionStore.handleObservableActionState(
            subscription$,
            featureName,
            commit,
            this.actions.validate.name,
            token
          ).toPromise();
          commit(featureName, this.mutations.setValue, result?.value ?? null);
          commit(featureName, this.mutations.setList, result?.list ?? []);
          return result;
        }
      ),
    ],
    getters: [
      ...ActionStore.feature.getters,
      ...KeyStore.feature.getters,
      ...ParamsStore.feature.getters,
      ...SubscriptionStore.feature.getters,
      select(this.getters.list, ({ state }) => state.list)
    ],
  };
}

export function create<
  T extends object = object,
  TList extends object = object,
  TParams extends object = object
>(): Store<T, TList, TParams> {
  return new Store<T, TList, TParams>();
}
