import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import { DynamicModule, EntityView, FieldOkResultConverter } from "@softline/dynamic";
import {MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule, Validators} from '@softline/ui-core';
import {distinctUntilChanged, Subscription} from 'rxjs';
import {Store} from '@softline/core';
import {
  CommandStore,
  handleRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import {SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE} from '../../auftrag.shared';
import * as WerkstattAuftraegeStore from '../../store/werkstatt-auftraege.store';
import {Auftrag} from '../../data/auftrag';
import {Router} from '@angular/router';
import {Kunde} from '../../data/kunde';
import {Verkaufsart} from '../../data/verkaufsart';
import {Objekt} from '../../data/objekt';
import {Fahrzeug} from '../../data/fahrzeug';

@Component({
  selector: 'soft-erstellen',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DynamicModule, UiCoreModule],
  templateUrl: './erstellen.component.html',
  styleUrls: ['./erstellen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErstellenComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;
  private subscription2?: Subscription;

  readonly kundeConverter = new FieldOkResultConverter({ 'id': 'id', 'nummer': 'number', '13': 'address', '14': 'zipCode', '15': 'city' });
  readonly verkaufsartwerkstattConverter = new FieldOkResultConverter({ 'bezeichnung': 'name', 'id': 'id' });
  readonly fahrzeugConverter = new FieldOkResultConverter({ 'id': 'id', 'bezeichnung': 'name' });
  readonly parameters = {
    freigabedatum: 'Alle',
    geloescht: 0,
    gesperrt: 2,
    grosskunde: '2',
    kategorie: '2,4',
    KUNDENABFRAGE_KENNZEICHEN_AKTIV: 'JA',
    lieferadresse: 0
  };

  readonly isCreating$ = this.store.observe(
    SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
    WerkstattAuftraegeStore.getters.saving
  );

  form = new FormGroup({
    customer: new FormControl<Kunde | undefined>(undefined, Validators.required()),
    title: new FormControl(undefined, Validators.required()),
    headerText: new FormControl<string | null>(null, Validators.required()),
    sellingType: new FormControl<Verkaufsart | undefined>(undefined, Validators.required()),
    object: new FormControl<Objekt | undefined>(undefined),
    vehicle: new FormControl<Fahrzeug | undefined>(undefined),
    kilometersStart: new FormControl<string | undefined>(undefined),
    operatingHours: new FormControl<string | undefined>(undefined)
  }, { updateOn: 'blur' });

  get composedTask(): any {
    const { title, customer, headerText, sellingType, vehicle, kilometersStart, operatingHours } = this.form.getRawValue();
    return  {
      id: undefined,
      number: 0,
      object: {
        id: null,
        name: title,
        number: null,
        invoiceRelease: null
      },
      sellingType,
      mileage: +(kilometersStart ?? 0),
      description: headerText,
      customer,
      vehicle: { id: (vehicle as any)?.id, name: (vehicle as any)?.name },
      operatingHours: +(operatingHours || 0),
      signature: null
    };
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store,
    private cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#WORKSHOP.COMPONENTS.CREATE_TASK.TITLE'
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
      WerkstattAuftraegeStore.actions.default.load,
      { data: {} }
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: ErstellenComponent,
        commands: [
          {
            class: 'menu main-menu main-menu-top',
            name: '#WORKSHOP.COMPONENTS.OVERVIEW.TITLE',
            icon : 'fa-regular fa-list',
            execute: async () => {
              await this.router.navigate(['/werkstaettenauftrag'])
            }
          }
        ]
      }
    )

    this.subscription = this.store.observe(
      SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
      WerkstattAuftraegeStore.getters.default.default
    ).subscribe(template => {
      this.form.controls.sellingType.patchValue(template?.sellingType, { onlySelf: false, emitEvent: false });
      this.cdRef.markForCheck();
    });

    this.subscription2 = this.form.controls.customer.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(customer => {
        this.store.dispatch(
          SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
          WerkstattAuftraegeStore.actions.default.load,
          {
            data: {
              customer,
              sellingType: customer !== null ? this.form.value?.sellingType : null
            }
          }
        );
      });
  }

  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    if (this.subscription2 && !this.subscription2.closed) {
      this.subscription2.unsubscribe();
    }

    this.subscription = undefined;
    this.subscription2 = undefined;
  }

  async onSubmit(): Promise<void> {
    const auftrag = await this.createTask();

    if (!auftrag) {
      return;
    }

    this.form.reset();

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title: 'Auftrag wurde erfolgreich erstellt!',
        actions: [{
          title: 'Anzeigen',
          type: 'link',
          onExecute: () => this.router.navigate(['/werkstaettenauftrag', auftrag.id])
        }]
      }
    )
  }

  private async createTask(): Promise<Auftrag | null> {
    try {
      return await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
        WerkstattAuftraegeStore.actions.create,
        { entity: this.composedTask }
      )
    } catch (e) {
      handleRequestErrors(this.store, e)
    }

    return null
  }
}
