import { inject } from "@angular/core";
import {
  FieldOkConfig,
  SOFTLINE_CONFIG_DEFAULT_FIELD_OK_VIEW,
  SOFTLINE_CONFIG_FIELD_OK
} from "@softline/dynamic";
import { isDefined } from "@softline/core";

export function fieldOkConfigFactory(name: string, options: {type?: string}): FieldOkConfig {
  let configs = inject(SOFTLINE_CONFIG_FIELD_OK, {optional: true}) ?? [];
  const fokConfigs = configs.filter(o => o?.name === name)
    .sort((f, s) => (s.priority ?? 0) - (f.priority ?? 0))
    .filter(
      o => (o.type ?? 'default') === (options?.type ?? 'default'));

  if(fokConfigs.length === 0) {
    const defaultView = inject(SOFTLINE_CONFIG_DEFAULT_FIELD_OK_VIEW);
    return {
      name,
      priority: 0,
      views: [defaultView]
    };
  }
  return fokConfigs[0];
}
