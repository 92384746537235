import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import moment from 'moment';
import {DateParser} from '../../../services/date.parser';
import {UserAgentService} from '../../../services/user-agent.service';
import {SOFTLINE_FEATURE_MODAL} from '../../../modal/modal.shared';
import * as ModalStore from "../../../modal/modal.store";
import {Store} from '@softline/core';

@Component({
  selector: 'soft-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
})
export class DateInputComponent implements ControlValueAccessor {

  private wasInside = false;
  onChange: Function = () => {};
  onTouch: Function = () => {};

  pickerValue: string | null = null;
  input: string | null = null;
  showPicker = false;
  hasDateSelected = false;

  @Input() format?: string;
  @Input() placeholder?: string;
  @Input() @HostBinding('class.readonly') readonly = false;

  @Input() changeTrigger: 'blur' | 'input' = 'blur';

  private _value: string | null | undefined;
  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    if (value === this._value) {
      // Fix for bug causing shown value = 'h' if entered twice
      this._value = '';
      this.cdRef.detectChanges();
    }
    this._value = value;
  }
  @Output() valueChange: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();

  constructor(
    private dateParser: DateParser<moment.Moment>,
    readonly userAgentService: UserAgentService,
    private cdRef: ChangeDetectorRef,
    private readonly store: Store
  ) {}

  @HostListener('click')
  clickedInside(): void {
    this.wasInside = true;
  }

  @HostListener('document:dblclick')
  @HostListener('document:click')
  clickedOutside(): void {
    if (!this.showPicker) return;

    if (!this.wasInside) this.closePicker();

    this.wasInside = false;
  }

  @HostListener('keydown.f4', ['$event']) onF4(event: KeyboardEvent): void {
    this.showPicker = !this.showPicker;
    this.pickerValue = this.value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.value = obj;
      this.input = obj;
      this.pickerValue = obj;
    } else {
      const value = this.dateParser.parse(obj);
      if(value) {
        this.value = value;
        if(value !== obj) {
          this.onChange(this._value);
          this.valueChange.emit(this._value);
        }
      }
      else
        this.pickerValue = obj;
    }
  }

  onInput(value: string | null): void {
    switch (this.changeTrigger) {
      case 'input':
        this.input = value;
        this.setValue(value, true);
        break;
      default:
        this.input = value;
        break;
    }
  }

  onBlur(value: string | null): void {
    if (this.changeTrigger === 'blur')
      this.setValue(value, true);
    if(this.value)
      this.input = null;
    this.pickerValue = this.value;
  }

  setValue(value: string | null, parse: boolean): void {
    const prevValue = this.value
    if (!value)
      this.value = null;
    else if (parse)
      this.value = this.dateParser.parse(value);
    else
      this.value = value;

    if (value === prevValue)
      return;
    this.onChange(this._value);
    this.valueChange.emit(this._value);
  }

  dateSelected(date: string | null): void {
    this.hasDateSelected = true;
    this.pickerValue = date;
  }

  presentPicker(): void {
    if (!this.readonly) return;
    this.clickedInside();
    this.showPicker = !this.showPicker;
  }

  closePicker(): void {
    this.showPicker = false;
    this.pickerValue = this.value;
    this.onTouch();
  }

  async openDatePickerModal(): Promise<void> {
    this.onTouch();

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.date,
      { value: this.value, dismiss: true }
    );

    if (!result || result === 'DISMISSED')
      return;

    this.setValue(result, false);
  }
}
