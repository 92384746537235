<div class="flex flex-col items-center">
  <div class="flex items-center justify-between w-full bg-secondary-300 border border-secondary-700 border-solid p-2 gap-2 flex-wrap">
    <div class="flex flex-row items-center gap-2">
      <div class="bg-secondary-500 text-secondary-contrast text-sm rounded-full px-2 whitespace-nowrap cursor-pointer hover:underline"
           [ngClass]="{'italic !text-light soft-label': !sort?.property}"
           (click)="onPropertyClick()">
        {{(sort?.property ? (sort?.property | sortPropertyName:config) : '#APPLICATION.FILTER_AND_SORT.SORT.FIELD_PLACEHOLDER') | translate }}
      </div>
      <div *ngIf="sort?.property as property"
           class="bg-secondary-500 text-secondary-contrast text-sm rounded-full px-2 whitespace-nowrap cursor-pointer hover:underline"
           [ngClass]="{'italic !text-light soft-label required': !sort?.direction}"
           (click)="onDirectionClick()">
        {{('#APPLICATION.FILTER_AND_SORT.SORT.' + (sort?.direction ?? 'DIRECTION_PLACEHOLDER')) | uppercase | translate}}
      </div>
    </div>
    <button (click)="removeFilter()" *ngIf="sort">
      <soft-icon name="fa-regular fa-xmark text-danger"></soft-icon>
    </button>
  </div>

</div>
