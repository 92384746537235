import * as CollectionStore from './collection.store';
import { Entity } from '../types/entity';
import { StoreFeature } from '../../../store';
import {
  createGetter,
  createMutation,
  mutate,
  select,
} from '../../../factories';

export interface State<T extends Entity = Entity>
  extends CollectionStore.State<T> {
  selected: string | number | null;
}

export class Store<T extends Entity = Entity> {
  mutations = {
    select: createMutation<State<T>, T | number | string>('select'),
  };

  getters = {
    selected: createGetter<State<T>, T | null | undefined>('selected'),
    selectedId: createGetter<State<T>, number | string | null>('selectedId'),
  };

  feature: Partial<StoreFeature<State<T>>> = {
    initialState: {
      selected: null,
    } as State<T>,
    mutations: [
      mutate<State<T>, T | number | string>(
        this.mutations.select,
        ({ state, params }) => {
          if (typeof params === 'object') params = params.id;
          if (state.ids.indexOf(params) === -1)
            throw new Error(
              `SelectStore - select: Cannot select id '${params}' (not in store)`
            );
          return { ...state, selected: params };
        }
      ),
      mutate<State<T>, T>(
        CollectionStore.mutations.remove,
        ({ state, params }) => {
          if (params.id === state.selected) return { ...state, selected: null };
          return state;
        }
      ),
      mutate<State<T>, T[]>(
        CollectionStore.mutations.removeMany,
        ({ state, params }) => {
          if (params.some((o) => o.id === state.selected))
            return { ...state, selected: null };
          return state;
        }
      ),
      mutate<State<T>, T>(CollectionStore.mutations.clear, ({ state }) => {
        return { ...state, selected: null };
      }),
    ],
    getters: [
      select<State<T>, undefined, T | null | undefined>(
        this.getters.selected,
        ({ state, params }) =>
          state.selected ? state.entities[state.selected] : null
      ),
      select<State<T>, undefined, number | string | null>(
        this.getters.selectedId,
        ({ state, params }) => state.selected
      ),
    ],
  };
}

export function create<T extends Entity>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const feature = instance.feature;
