<div class="flex flex-col mt-5 md:mt-2 mb-1 px-4 md:px-0">
  <div class="flex flex-col mb-2">
    <h2 class="text-xl font-semibold">Aktion auswählen</h2>
    <p class="text-sm text-light">Bitte wählen Sie die durchzuführende Aktion für den ausgewählten Artikel aus</p>
  </div>

  <div class="flex flex-col gap-1 rounded border border-gray-200 bg-gray-100 border-solid p-2">
    <p class="text-sm color-light mr-2">{{ item?.artikel?.number }}</p>

    @if (item?.customization && item?.customization?.ARTBEZ1) {
      <p class="text-sm font-semibold mb-0">{{ item?.customization?.ARTBEZ1 }}</p>
      <p class="text-sm color-light mb-0">{{ item?.customization?.ARTBEZ2 }}</p>
    } @else {
      <p class="text-sm font-semibold mb-0">{{ item?.artikel?.name || '' }}</p>
    }

    @if (item?.artikel?.supplierItemNumber) {
      <div class="text-sm color-light">{{item?.artikel?.supplierItemNumber}}</div>
    }
  </div>
</div>

<ul class="flex flex-col px-4 md:px-0 mb-1 mt-3">
  <span class="soft-overline mb-2">Aktionen</span>
  <li
    (click)="close('addAgain')"
    class="px-0 py-3 text-lg flex gap-3 items-center justify-between hover:bg-hover cursor-pointer border-b border-t border-b-gray-100 border-t-gray-100 border-b-solid border-t-solid">
    Artikel erneut hinzufügen
  </li>
  <li
    (click)="close('delete')"
    class="px-0 py-3 text-lg flex gap-3 items-center justify-between hover:bg-hover cursor-pointer">
    Artikel löschen
  </li>
</ul>

<button class="soft-button secondary w-full h-14 text-center" (click)="close(null)">Abbrechen</button>

