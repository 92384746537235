import { Inject, Injectable, NgZone, Optional } from "@angular/core";
import { TelemetryBaggageService } from "./telemetry-baggage.service";
import { MethodCall, TelemetryBaggage } from "../types/telemetry";
import { SOFTLINE_SERVICE_UUID } from "../../core.shared";
import { Store } from "../../store/store";
import { isDefined } from "../../functions/is-defined.function";

@Injectable()
export class TelemetryService {

  sessionId: string;
  id: string | null = null;
  baggage: TelemetryBaggage = { messages: [], calls: [] };

  constructor(private store: Store,
              @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
              @Inject(TelemetryBaggageService) @Optional() private baggageServices: TelemetryBaggageService[],
              private zone: NgZone) {
    this.sessionId = this.uuid().replace(/-/g, '');
  }

  start(): string {
    if(this.id) {
      console.error("[TracingService] start: trace already started");
      return this.id;
    }

    this.id = this.uuid().replace(/-/g, '');

    this.baggage = { messages: [], calls: [] };

    for (const service of (this.baggageServices ?? [])) {
      const baggage = service.get();
      if(isDefined(baggage['message']) || isDefined(baggage['stack'])) {
        console.error("[TracingService] start: cannot add baggage called 'message' or 'stack'")
        continue;
      }
      this.baggage = {...this.baggage, ...baggage};
    }

    return this.id;
  }

  addBaggage(name: string, value: object): void {
    if(!this.id) {
      console.error("[TracingService] add: no trace started")
      return;
    }
    this.baggage = { ...this.baggage, [name]: value };
  }

  addMessage(message: string): void {
    if(!this.id) {
      console.error("[TracingService] addMessage: no trace started")
      return;
    }
    this.baggage.messages.push(message)
  }

  addCall(calls: MethodCall): void {
    if(!this.id) {
      console.error("[TracingService] addStack: no trace started")
      return;
    }
    this.baggage.calls.push(calls)
  }

  stop(): void {
    this.id = null;
    this.baggage = { messages: [], calls: [] };
  }

}
