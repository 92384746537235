<div class="c column" *ngIf="form && definition" [formGroup]="form">
  <label class="soft-label top"
    >{{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-file-input
    [formControlName]="definition.name"
    [readonly]="definition.readonly | booleanRule: form.value"
    softFieldValidation
    #value
  ></soft-file-input>
</div>
