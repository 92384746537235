<div
  *ngIf="form && definition"
  class="soft-container soft-container-column"
  [formGroup]="form"
>
  <label class="soft-label soft-label-top">
    {{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-value-converter
    [formControlName]="definition.name"
    [converter]="definition.resultMapping | resultMappingConverter">
    <soft-field-ok
      [name]="definition.fieldOk"
      [parameters]="definition.parameters || {} | resolveParameters: form.value"
      [readonly]="definition.readonly | booleanRule: form.value"
      [placeholder]="definition.placeholder"
      [template]="definition.format ? template : undefined"
      [title]="definition.title"
      softFieldValidation
      #convertValue
    >
    </soft-field-ok>
  </soft-value-converter>
</div>

<ng-template let-entity="entity" #template>
  <span>
    {{ definition.format | formatRule: form.value | interpolate: entity }}
  </span>
</ng-template>
