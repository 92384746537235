import { ENVIRONMENT_INITIALIZER, inject, Injector, StaticProvider } from "@angular/core";
import {
  LanguageStore,
  SOFTLINE_CONFIG_DEFAULT_LANGUAGE,
  SOFTLINE_CONFIG_LANGUAGES,
  SOFTLINE_FEATURE_LANGUAGE, SOFTLINE_LANGUAGE_DE,
  Store
} from "@softline/core";
import { LanguageService } from "./language.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LanguageInterceptor } from "./language.interceptor";

export const languageProviders: StaticProvider[] = [
  { provide: SOFTLINE_CONFIG_LANGUAGES, useValue: [SOFTLINE_LANGUAGE_DE]},
  { provide: SOFTLINE_CONFIG_DEFAULT_LANGUAGE, useValue: "de"},
  { provide: HTTP_INTERCEPTORS, useFactory: (store: Store) => new LanguageInterceptor(store), deps: [Store], multi: true},
  { provide: LanguageService, useClass: LanguageService },
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      const injector = inject(Injector);
      const languages = inject(SOFTLINE_CONFIG_LANGUAGES);
      const defaultLanguage = inject(SOFTLINE_CONFIG_DEFAULT_LANGUAGE);
      store.registerFeature(SOFTLINE_FEATURE_LANGUAGE, LanguageStore.feature, injector);

      let selected = defaultLanguage;
      if(languages.findIndex(o => o.code === defaultLanguage) === -1)
        selected = languages[0].code;
      store.commit(SOFTLINE_FEATURE_LANGUAGE,
        LanguageStore.mutations.init,
        {languages, selected}
      );
      if(languages.length > 1)
        store.dispatch(SOFTLINE_FEATURE_LANGUAGE, LanguageStore.actions.load)
    }, multi: true}
]
