<div class="soft-container column" [formGroup]="form">
  <label class="soft-label soft-label-top">
    {{ definition?.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-list [formControlName]="definition.name" #list>
    <ng-template let-item="item">
      <soft-dynamic-object-value
        [definition]="definition.definition"
        [value]="item"
      ></soft-dynamic-object-value>
    </ng-template>
  </soft-list>
  <div class="c row end">
    <span *ngIf="list.selected">
      <button
        class="soft-button link danger"
        (click)="onDeleteClick(list.selected)"
      >
        <i class="fa-regular fa-delete-left"></i>
        Löschen
      </button>
      |
    </span>
    <span *ngIf="list.selected">
      <button class="soft-button link" (click)="onEditClick(list.selected)">
        <i class="fa-regular fa-pen-to-square"></i>
        Bearbeiten
      </button>
      |
    </span>
    <span>
      <button class="soft-button link" (click)="onAddClick()">
        <i class="fa-regular fa-plus"></i>
        Hinzufügen
      </button>
    </span>
  </div>
  <soft-validation-container
    class="soft-property-validation"
    *ngIf="form.controls[definition.name].touched"
    [validation]="form | combineValidations: definition.name"
  ></soft-validation-container>
</div>
{{ form.value | json }}
{{ definition | json }}
