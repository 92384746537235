import { Inject, Injectable } from "@angular/core";
import { publish } from "rxjs";
import {
  Filter,
  FilterOptions,
  FilterRegistration,
  FilterPredicateSelector,
  SOFTLINE_REGISTRATION_FILTER
} from "../types/filter";

@Injectable()
export class FilterProvider {
  constructor(@Inject(SOFTLINE_REGISTRATION_FILTER) private registrations: FilterRegistration<unknown, Filter>[]) {
  }


  getFilter(operator: string): (value: unknown, options: FilterOptions<Filter>, getPredicate: FilterPredicateSelector) => boolean {
    const filterReg = this.registrations
      .find(o => o.operator === operator);
    if(!filterReg)
      throw new Error(`[FilterService] predicate: No such filter with operator named: ${operator}`);
    return filterReg.predicate;
  }
}
