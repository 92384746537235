<div class="flex flex-col-reverse full-width bg-surface lg:bg-transparent -mt-2">
  <soft-step-header [steps]="steps" [currentIndex]="0" #stepper></soft-step-header>
  <soft-page-header [overline]="stepper.stepText"
                    [title]="stepper.current?.header | translate"
                    [subtitle]="stepper.current?.subHeader | translate"></soft-page-header>
</div>

<div class="mt-4">
  <label for="softapps-lager-lagerstand-artikeleingabe-artikel"
         class="soft-label top">{{'#LAGERSTAND.PAGES.ARTIKEL_EINGABE.ARTIKEL' | translate}}</label>
  <soft-field-ok id="softapps-lager-lagerstand-artikeleingabe-artikel"
                 name="artstamm"
                 placeholder="Artikelnummer"
                 [template]="artikelTemplate"
                 (valueChange)="onItemChange($event)"
                 #artstamm>
    <ng-template #artikelTemplate let-entity="entity">
      {{ entity?.cartikelnummer }} - {{ entity?.artbez1 }}
      <ng-container *ngIf="entity?.artbez2 && entity?.artbez2?.length > 0">- {{ entity?.artbez2 }}</ng-container>&nbsp;
      <ng-container *ngIf="entity?.artbez3 && entity?.artbez3?.length > 0">- {{ entity?.artbez3 }}</ng-container>
    </ng-template>
  </soft-field-ok>
</div>
<div class="mt-4 text-light flex flex-row justify-center">oder</div>
<soft-scan-button class="mt-4"></soft-scan-button>
<soft-blocking-loading-spinner *ngIf="loading$ | async" class="top-0 right-0"></soft-blocking-loading-spinner>
