import { effect, inject, Injectable, OnDestroy, OnInit, signal, Signal } from "@angular/core";
import { hasOnDestroy, Store } from "@softline/core";
import { SOFTLINE_FEATURE_TITLE } from "../application.shared";
import * as TitleStore from "../program/title.store";

type Constructor<T> = new(...args: any[]) => T;

export const WithTitle = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class TitleMixin extends Base implements OnDestroy {

    store = inject(Store);
    abstract title: Signal<string | null | undefined>;
    subtitle: Signal<string | null | undefined> = signal(null);

    titleEffect = effect(() => {
      this.store.commit(
        SOFTLINE_FEATURE_TITLE,
        TitleStore.mutations.setTitle,
        this.title() ?? ''
      );
    });

    subtitleEffect = effect(() => {
      this.store.commit(
        SOFTLINE_FEATURE_TITLE,
        TitleStore.mutations.setSubTitle,
        this.subtitle()
      );
    });


    constructor(...args: any[]) {
      super(...args);
    }

    ngOnDestroy() {
      this.store.commit(
        SOFTLINE_FEATURE_TITLE,
        TitleStore.mutations.setTitle,
        ''
      )
      this.store.commit(
        SOFTLINE_FEATURE_TITLE,
        TitleStore.mutations.setSubTitle,
        ''
      )
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return TitleMixin;
}
