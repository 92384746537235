<h4 class="font-semibold text-xl mt-2 mb-1">{{ '#WORKSHOP.DIALOGS.END_ACTIVITY.TITLE' | translate }}</h4>

<p class="soft-text-s">
  {{ '#WORKSHOP.DIALOGS.END_ACTIVITY.DESCRIPTION' | translate }}
</p>

<soft-message-bar-container [name]="'END_ACTIVITY_MSG_BAR'">
  <soft-message-bar></soft-message-bar>
</soft-message-bar-container>

<div class="c row no-wrap w-full mt-4">
  <button class="soft-button h-16 text-center w-full secondary fill-force mr-2" (click)="close(null)">{{ '#WORKSHOP.DIALOGS.ABORT' | translate }}</button>
  <button class="soft-button h-16 text-center w-full primary fill-force ml-2" (click)="stopActivity()">{{ '#WORKSHOP.DIALOGS.END_ACTIVITY.STOP' | translate }}</button>
</div>
