import {
  Component,
  EventEmitter,
  HostListener, inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {FormGroup, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidatorFn} from "@angular/forms";
import {Dictionary, isDefinedNotEmpty} from '@softline/core';
import {ControlDefinition, Definition, isControlDefinition, ObjectDefinition,} from '../../data/definitions';
import {RuleHelper} from '../../utilities/rule.helper';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DateParser, Validators} from '@softline/ui-core';
import {AffectedHelper} from "../../utilities/affected.helper";
import { DynamicFormCreator } from "../../services/dynamic-form-creator";

@Component({
  selector: 'soft-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent<T> implements OnInit, OnDestroy {
  private dateParser = inject(DateParser);
  private injector = inject(Injector);

  private subscription?: Subscription;
  private initSubscription?: Subscription;
  private _definition: ObjectDefinition | undefined | null = undefined;

  private _runAfterInit?: (form: FormGroup) => void

  readonly formInitialized$ = new BehaviorSubject(false);

  @Input()
  get definition(): ObjectDefinition | undefined | null {
    return this._definition;
  }
  set definition(value: ObjectDefinition | undefined | null) {
    this._definition = value;
    if (value) {
      const creator = new DynamicFormCreator(this.dateParser, this.injector);
      this.form =  creator.createForm(value);

      if (this.value)
        this.form.patchValue(this.value);

      if (this.subscription && !this.subscription.closed)
        this.subscription.unsubscribe();
      this.subscription = this.form.valueChanges.subscribe((_) => {
        this._value = this.form.value;
        this.valueChange.emit(this.form.value);
      });

      this.formInitialized$.next(true);
      this._runAfterInit?.(this.form);
    } else
      this.form = new UntypedFormGroup({});
  }

  private _value?: T;
  @Input()
  get value(): T | undefined {
    return this._value;
  }
  set value(value: T | undefined) {
    if (value === this._value) return;

    this._value = value;
    if (value) this.form.patchValue(value);
  }
  @Output() valueChange: EventEmitter<T> = new EventEmitter<T>();
  @Output() submit: EventEmitter<T> = new EventEmitter<T>();
  @Output() reset: EventEmitter<T> = new EventEmitter<T>();

  @ViewChild('formRef') formRef?: FormGroupDirective;

  form = new UntypedFormGroup({});

  constructor() {}

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.subscribe((_) => {
      this._value = this.form.value;
      this.valueChange.emit(this.form.value);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    if (this.initSubscription && !this.initSubscription.closed)
      this.initSubscription.unsubscribe();

    this.subscription = undefined;
    this.initSubscription = undefined;
  }

  private getDefaultValue(definition: ObjectDefinition): T {
    const defaultValue: Dictionary<any> = {};
    for (const propertyDefinition of definition?.definitions?.filter(
      isControlDefinition
    ))
      defaultValue[propertyDefinition.name] =
        propertyDefinition.default ??
        (propertyDefinition.name === 'boolean' ? false : undefined);
    return defaultValue as T;
  }

  @HostListener('window:keydown.enter', ['$event'])
  onSubmit(): void {
    if(!this.form.valid || this.form.disabled)
      return;
    this._value = this.form.value;
    this.submit.emit(this.form.value);
  }

  onNativeSubmit(event: Event): void {
    event.stopPropagation();
  }

  async waitForInit(): Promise<void> {
    return new Promise(resolve => {
      this.initSubscription = this.formInitialized$.subscribe(done => {
        if (!done) return;
        resolve();
        this.initSubscription?.unsubscribe();
        this.initSubscription = undefined;
      })
    })
  }

  afterInit(callback: (form: FormGroup) => void) {
    this._runAfterInit = callback
  }

  onReset(event?: Event): void {
    if (!this.definition) return;
    const defaultValue = this.getDefaultValue(this.definition);
    this.form.reset(defaultValue);
    this.reset.emit(defaultValue);
    if(event)
      event.stopPropagation();
  }
}
