<form [formGroup]="form">
  <div class="soft-card">
    <div class="soft-overline">Allgemein</div>
    <div class="">
      <label class="soft-label top">Startup App</label>
      <soft-select formControlName="startup" (valueChange)="set()">
        <soft-select-option [value]="undefined"><span class="text-light">Standard</span></soft-select-option>
        @for (app of apps$ | async; track app.route) {
          <soft-select-option [value]="app.route">{{ app.name | translate }}</soft-select-option>
        }
      </soft-select>
    </div>
  </div>
</form>
