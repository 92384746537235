import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { QueryParameters } from '@softline/core';
import { BehaviorSubject } from 'rxjs';
import { EntityDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
  selector: 'soft-dynamic-entity-input',
  templateUrl: './entity-input.component.html',
  styleUrls: ['./entity-input.component.scss'],
})
export class EntityInputComponent
  extends DynamicInputAtom<EntityDefinition>
  implements OnInit
{
  entities$: BehaviorSubject<unknown[]> = new BehaviorSubject<unknown[]>([]);

  constructor(private service: HttpClient) {
    super();
  }

  ngOnInit(): void {}

  onQuery(queryParameters: QueryParameters<unknown>): void {
    console.log(queryParameters);
    if (!this.definition) return;
    this.service
      .get<unknown[]>(this.definition.endpoint)
      .subscribe((o) => this.entities$.next(o));
  }
}
