import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import {map, Subscription} from 'rxjs';
import {
  SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK,
  SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_ARTIKEL_DETAILS, SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_BEWE
} from '../etikettendruck.shared';
import {EtikettendruckStore} from '../store/etikettendruckStore';
import {EtikettendruckArtikelDetailStore} from '../store/etikettendruck-artikel-detail.store';
import {EtikettendruckBeweStore} from '../store/etikettendruck-bewe.store';

@Component({
  selector: 'soft-mde-etikettendruck',
  standalone: true,
  templateUrl: './etikettendruck.component.html',
  styleUrls: ['./etikettendruck.component.scss'],
  imports: [RouterModule],
})
export class EtikettendruckComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Etikettendruck'
    );

    this.subscription = this.activatedRoute.data.subscribe(async ({ etikettendruck }) => {
      const artikel = this.store.get(SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_BEWE, EtikettendruckBeweStore.getters.all);

      if (!etikettendruck?.etikettenart || !artikel || artikel.length < 1) {
        await this.router.navigate(['/mde-etikettendruck/auswahl/kategorie'])
      }
    });

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: EtikettendruckComponent,
        commands: [
          {
            name: 'Etikettendruck',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-print',
            isVisible: this.store.observe(SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK, EtikettendruckStore.getters.art).pipe(map(o => !!o)),
            canExecute: this.store.observe(SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK, EtikettendruckStore.getters.art).pipe(map(o => !!o)),
            routerLink: '/mde-etikettendruck'
          },
        ]
      }
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      EtikettendruckComponent
    );


    this.store.commit(
      SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_ARTIKEL_DETAILS,
      EtikettendruckArtikelDetailStore.mutations.clear,
    );

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }
}
